import React, { useEffect, useState } from "react";
import transactionServices from "../../services/transactionServices";
import { useSelector } from "react-redux";
import { convertToIndianFormat, getTitle } from "../../utils/functions";
import moment from "moment";
import { AmountPaidCommited, DetailButton, TableRow, TableRowImg, TableRowMultilineInfo, TxnRefNumber } from "../../components/miniComponents";
import CustomTable from "../../components/customTable";

const CanceledTransactions = () => {
  const [tableData, setTableData] = useState();
  const user = useSelector(state => state.user.data);
  console.log(user);
  

  const getCanceledTransaction = async () => {
    const data = {
      investorId: user._id,
      statusArray: ["REJECTED"],
    };

    try {
      const res = await transactionServices.getTransactionByLisitngAndUser(
        data
      );


      if (res && res.success && res.data.length > 0) {
        let tempData = [];
    
        for (const e of res.data) {
            if (!e?.listingId) continue;
    
            let rejectreason = e.rejectReason;
            if (rejectreason && rejectreason.includes("investor")) {
                e.rejectReason = rejectreason.replace("investor", user.username);
            }    
            const temp = {
                propertyInfo: [
                    getTitle(e.listing) + "-" + e.project.projectName || e.project.projectName,
                    res.url + e.listingImages[0]?.docUrl || "",
                    e.project.projectName,
                    e.listing.propertyCode
                ],
                propertyId: e.listingId,
                projectName: e.project.projectName,
                area: e.noOfFracs || 0,
                amount: convertToIndianFormat(e.amount) || 0,
                created_at: moment(e.created_at).format("DD/MM/YYYY") || "12/12/2000",
                listingId: e.listingId,
                projectId: e.projectId,
                _id: e._id,
                refNo: e.transactionId,
                reasson: e.rejectReason || "Cancelled"
            };
            tempData.push(temp);
        }
        setTableData(tempData);
    }
    
    } catch (error) {
      console.log("somting went worng", error);
    }
  };

  useEffect(() => {
    getCanceledTransaction();
  }, []);
  

  const tableHeads = [
    {
        key:"propertyName",
        title:"Property Image"
    },
    {
        key:"propertyInfo",
        title:"Property Info"
    },
    // {
    //     key:"propertyType",
    //     title:"Property Type"
    // },
    {
      key:"amount",
      title:"Amount Commited"
  },
  
    {
        key:"area",
        title:"No of\nFRAX"
    },
    // {
    //     key:"pendingAmt",
    //     title:"Pending Amt"
    // },
    {
        key:"created_at",
        title:"Committed\nDate"
    },
    {
        key:"refNo",
        title:"Transaction\nId",
    },
    {
        key:"reasson",
        title:"Cancelled By"
    },
  ];

    const tableCells =[<TableRowImg/>, <TableRowMultilineInfo/>, <TableRow/>,
      <TableRow/>,<TableRow/>,<TxnRefNumber/>,<TableRow/>
    ]
  return (
    <>
    <CustomTable tableHeads={tableHeads} tableCells={tableCells} data={tableData} />
    </>
  );
};

export default CanceledTransactions;
