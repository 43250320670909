import React, { useEffect, useState } from 'react'
import ModalComponent from './modalComponent'
import { Box, Button, Stack, Typography } from '@mui/material'
import Lottie from 'lottie-react';
import serverError from "../../animations/serverError.json"


export default function AlgoErrorMsg({open,handleClose}) {
    const [animatingWar, setAnimatingWar] = useState(false);
    useEffect(() => {
        if (open) {
            setAnimatingWar(true);
        }
      }, [open]);
    
  return (
    <ModalComponent open={open} handleClose={handleClose}>
          <Box sx={{ textAlign: 'center', bgcolor: '#fdedec', borderRadius: 2, padding: "24px 12px" }}>
              <Stack sx={{ alignItems: 'center', justifyContent: 'center', bgcolor: '#fdedec', borderRadius: 2, padding: 1, minWidth: { md: '600px' }, mt: 2 }}>
                  {
                      animatingWar &&
                      <Box sx={{ width: 150, marginTop: "-35px" }}>
                          <Lottie animationData={serverError} loop={true} />
                      </Box>

                  }
                  
                    <Typography variant='landingSubTitle' sx={{ textAlign:'center',lineHeight:'35px'}}>Uh-Oh, it’s not you it’s us <br></br>Please try again after some time <br></br>
                    <span style={{fontSize:'16px'}}>If the issue persists, reach out to us at  
                    <a href="mailto:hi@realx.in"  style={{textDecoration: 'none',paddingLeft:'5px'}}>hi@realx.in</a>
                    </span>
                    </Typography>
                                     
              </Stack>
          </Box>      
    </ModalComponent>
  )
}
