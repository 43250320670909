import { Box, Button, LinearProgress, Stack, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import transactionServices from "../services/transactionServices";
import { calculatePercent, convertToIndianFormat, getTitle } from "../utils/functions";
import CustomIcon from "./customIcon";
import CustomTable from "./customTable";
import { useSnackbar } from "notistack";
import { DetailButton, StatusRow, TableRow, TableRowImg, TableRowMultilineInfo ,TxnRefNumber,AmountPaidCommited, TableRowMultilinePropertyinfo} from "./miniComponents";
import PaymentMode from "./modals/paymentMode";
import PdfViewer from "./modals/pdfViewer";
import CompleteSecondPartPaymentModal from "./modals/completeSecondPartPayment";
import ModalComponent from "./modals/modalComponent";
import AllTransactionByListing from "./transactionTable/AllTransactionByListingNdUser";


export const PropertyTable = ({emptyState, sendDataToParent})=>{
    const user = useSelector(state => state.user.data);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [data,setData] = useState([]);
    const [openTransactionModal,setOpenTransationModal] = useState(false);
    const [transactionProp,setTransactionProp] = useState({});
    // const [openSaleDeed, setOpenSaleDeed] = useState(false);
    // const [saleDeedUrl, setSaleDeedUrl] = useState(null);

    useEffect(()=>{
        getMyInvestments();
    },[])

    const getMyInvestments = async()=>{
        let reqData = {
            investorId:user._id
        }
        const res = await transactionServices.getPortfolioDetails(user._id);
        
        if(res && res.success && res.data?.length>0){
            let tempData = [];
            for(let e of res.data) {
                if(!e?.listingId) continue;
                
                let temp = {
                    propertyInfo: 
                        [
                            getTitle(e) +"-" + e.projectName|| e.projectName, 
                            res.url + e?.listingImages || "",
                            e.projectName,
                            e.propertyCode
                        ],
                        rightToOwnerShip:e.rightToOwnerShip?"Registered Co-Ownership":"Tokenization",
                    propertyId: e.listingId,
                    projectName: e.projectName,
                    frax: e.totalNoOfFracs,
                    investAmm:convertToIndianFormat(e.totalAmtToBePaid) || 0,
                    // pendingAmount: convertToIndianFormat(e.pendingAmt) || 0,
                    date:moment(e.created_at).format("DD/MM/YYYY") || 12/12/2000,
                    uDate:moment(e.created_at).format("DD/MM/YYYY") || 12/12/2000,
                    percentInvested:`${e.totalPropertyPercent.toFixed(2)} %`,
                    status:e.status,
                    listingId:e.listingId,
                    projectId:e.projectId,
                    _id:e._id,
                    transactionId: e.transactionId,
                    saleDeed: (res.url + e?.listing?.registrationCertificate[0]?.docUrl) || "",
                }
                tempData.push(temp);
            }
            setData(tempData);
            sendDataToParent(tempData);
        }
    }

    const tableHeads = [
        {
            key:"propertyName",
            title:"Property Image"
        },
        {
            key:"propertyInfo",
            title:"Property Info"
        },
        {
            key:"investAmm",
            title:"Total Amount Invested"
        },
        {
            key:"frax",
            title:"Total No of FRAX"
        },
        {
            key:"rightToOwnerShip",
            title:"Investment Mode"
        },
        // {
        //     key:"date", 
        //     title:"Committed Date"
        // },
        // {
        //     key:"date", 
        //     title:"Paid"
        // },
        
        {
            key:"percentInvested",
            title:"Total % Ownership"
        },
        // {
        //     key:"status",
        //     title:"Transaction Ref No",
        //     statusMap:{
        //         "CREATED":{
        //             title:"Payment Pending",
        //             color:"warning"
        //         },
        //         "INVESTORPAID":{
        //             title:"Payment Completed - Confirmation Pending",
        //             color:"warning"
        //         },
        //         "SECONDPARTINVESTORPAID":{
        //             title:"Second Part Payment Completed - Confirmation Pending",
        //             color:"warning"
        //         },
        //         "APPROVED":{
        //             title:"Payment Completed",
        //             color:"success"
        //         },
        //         "SECONDPENDING":{
        //             title:"Second Part of Payment Pending",
        //             color:"warning"
        //         },
        //     }
        // },
        // {
        //     key:"transactionId",
        //     title:"Transaction Ref No",
        // },
        {
            key:"click",
            title:"",
            buttonTitle: "View Sale Deed",
            onClick:(data)=>{
                if(data.saleDeed) {
                    if(data.status == "CREATED" || data.status == "INVESTORPAID") enqueueSnackbar("Payment not approved by RealX admin",{variant:"error",anchorOrigin:{horizontal:"right",vertical:"top"},autoHideDuration:3000});
                    else window.open("https://go.realx.in/sale-deed/" + data?.listingId, '_blank');
                }
            }
        },
        {
            key:"click",
            title:"",
            buttonTitle:"View Transactions",
            onClick:(data)=>{
                
                setTransactionProp({investorId:user._id,listingId:data.listingId, rco:data.rightToOwnerShip !=="Tokenization",statusArray:["APPROVED", "FRAXTRANSFERED"],pendingAmt:true})
                setOpenTransationModal((prev)=>!prev)
            }
        }
        // {
        //     key:"click",
        //     title:"",
        //     buttonTitle:"View Details",
        //     onClick:(data)=>{
        //         console.log("click : ", data);
        //         if(data.status === "CREATED"){
        //             navigate(`/dashboard/property/${data.listingId}/invest`,{state:{id:data.listingId}})
        //         }
        //         else{
        //             navigate(`/dashboard/property/${data.listingId}`,{state:{id:data.listingId}})
        //         }
        //     }
        // },
    ];

    

    const tableCells = [<TableRowImg />, <TableRowMultilineInfo />, <TableRow />,<TableRow />, <TableRow />,<TableRow />, <DetailButton />, <DetailButton />];
    if(data.length === 0)
    return (emptyState? emptyState :<EmptyTableState showAction={true} title={"No Properties"} subtitle={"You've not made any investments yet."} />)
    // eslint-disable-next-line
    return (
      <>
        {/* <PdfViewer open={openSaleDeed} setOpen={setOpenSaleDeed} url={saleDeedUrl} /> */}
        <CustomTable
          tableHeads={tableHeads}
          tableCells={tableCells}
          data={data}
        />

        {openTransactionModal && (
          <ModalComponent
            open={openTransactionModal}
            handleClose={() => setOpenTransationModal((prev) => !prev)}
            title="All transations"
            titleStyle={{fontWeight: 400, mb: 1, pl: 1, fontSize: "1.4rem !important"}}
            children={<AllTransactionByListing data={transactionProp}/>}
          />
        )}
      </>
    );
}

export const WatchListTable = ({watchlist})=>{

    const [data,setData] = useState([]);
    
    const navigate = useNavigate();


    useEffect(()=>{
        const temp = [];
        if(watchlist && watchlist.length>0){
            watchlist.map((e)=>{
                temp.push({
                    _id:e._id,
                    propertyName:getTitle(e),
                    propertyType:e.propertyType,
                    totalPurchasePrice:convertToIndianFormat(e.transactionDetails.totalCostOfPurchase),
                    totalInvestors:e.transactions?.investors?.length || 0,
                    filled:calculatePercent((e.numberOfFracs - e.remainingFracs),e.numberOfFracs).toFixed(2)
                });
                return null;
            });
        }
        setData(temp)
    },[watchlist])

    const tableHeads = [
        {
            key:"propertyName",
            title:"Property Name"
        },
        {
            key:"propertyType",
            title:"Property Type"
        },
        {
            key:"totalPurchasePrice",
            title:"Total Purchase Price"
        },
        {
            key:"totalInvestors",
            title:"Total no of investors"
        },
        {
            key:"filled",
            title:"% Filled"
        },
        {
            key:"click",
            title:"",
            onClick:(data)=>{
                console.log(data);
                    navigate(`/dashboard/property/${data._id}`,{state:{id:data._id}})
            }
        },
    ];


    // const DetailButton = (props) =>{
    //     return(
    //         <Button onClick={()=>navigate(`/marketplace/property/${data.propertyName}`,{state:{id:props.children}})} variant="outlined">Show Details</Button>
    //     )
    // }

    const FraxRow = (props) =>{
        // console.log();
        return(
            <Stack direction={"row"} spacing={3} alignItems={"center"}>
               <LinearProgress value={props.children} variant="determinate" color="success" sx={{height:9,width:120}}  /> 
               <Typography variant="body2">{props.children} %</Typography>
            </Stack>
        )
    }

    const tableCells = [<TableRow />,<TableRow />,<TableRow />,<TableRow />,<FraxRow />,<DetailButton />];

    if(data.length >0){
        return(
            <CustomTable tableHeads={tableHeads} tableCells={tableCells} data={data} />
        )
    }
    else{
        return (<EmptyTableState showAction={true} title={"No Properties"} subtitle={""} />)
    }
}



const EmptyTableState = ({showAction=false,title,subtitle}) =>{
    const navigate = useNavigate();
    return(
        <Box
        sx={{
            flex:1,
            width:"100%",
            // bgcolor:"red"
        }}
    >
        <Stack height={"100%"} direction={"column"} alignItems={"center"} justifyContent={"center"}>
            <Box 
                sx={{
                    width:{md:160,xs:88},height:{md:160,xs:88},
                    bgcolor:"primary.light",
                    borderRadius:"100%",
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center",
                    mt:{md:0,xs:3}
                }}
            >
                <CustomIcon name="file-document" sx={{bgcolor:"primary.main",width:{md:72,xs:32},height:{md:72,xs:32},}} />
            </Box>
            <Typography sx={{fontSize:"28px",fontWeight:500,mt:{md:4,xs:3},mb:{md:1.5,xs:0.5}}}>{title}</Typography>
            <Typography variant="subtitle1" sx={{fontSize:"16px",fontWeight:300,mb:{md:3,xs:2}}}>{subtitle}</Typography>
            {showAction && <Button onClick={()=>navigate("/dashboard")} sx={{mb:{md:0,xs:3}}} variant="contained">Explore Properties to invest</Button>}
        </Stack>
    </Box>
    )
}

export const TransactionsTable = ({transactionsDetails,getTransactionDetails})=>{
    const [data,setData] = useState([]);
    const [paymentModeModal,setPaymentModeModal] = useState(false);
    const [paymentModal,setPaymentModal] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const user = useSelector(state=>state.user.data);
    const [currentTraxn,setCurrentTraxn] = useState();
    const [reqData,setReqData] = useState()

    useEffect(()=>{
        const temp = [];
        if(transactionsDetails && transactionsDetails.length>0){
            transactionsDetails.map((e)=>{
                temp.push({
                    _id:e._id,
                    propertyInfo: 
                    [
                        getTitle(e.listing) || "", 
                        transactionsDetails[0].url + e?.listing?.listingImages[0]?.docUrl || "",
                        e.project.projectName,
                        e.listing.propertyCode
                    ],
                    rpf: (e?.rpf || e?.amount / e?.noOfFracs)?.toFixed(2),
                    projectName: e.project.projectName,
                    propertyName:getTitle(e.listing),
                    propertyType:e.listing?.propertyType,
                    amount:convertToIndianFormat(e.amount),
                    amtTobePaid: e?.amtTobePaid || 0,
                    area:e?.noOfFracs || 0,
                    propertyPercent: e.propertyPercent,
                    propertyStatus: e.listing?.status || "",
                    pendingAmt: convertToIndianFormat(e.pendingAmt) || 0,
                    remAmt:e?.pendingAmt > 0 ? e?.pendingAmt : 0,
                    highValueCashback: e.highValueCashback,
                    created_at: moment(e.created_at).format("DD/MM/YYYY"),
                    updated_at: moment(e.updated_at).format("DD/MM/YYYY"),
                    couponCashback: e.couponCashback,
                    status: e.status,
                    refNo: e.transactionId
                });
                return null;
            });
        }
        setData(temp)
    },[transactionsDetails])

    const tableHeads = [
        {
            key:"propertyName",
            title:"Property Image"
        },
        // {
        //     key:"projectName",
        //     title:"Project Name"
        // },
        {
            key:"propertyInfo",
            title:"Property Info"
        },
        // {
        //     key:"propertyType",
        //     title:"Property Type"
        // },
        {
            key:"amount",
            title:"Amount Paid\n / Commited"
        },
      
        {
            key:"area",
            title:"No of\nFRAX"
        },
        {
            key:"rpf",
            title:"Rate /\nFRAX"
        },
        // {
        //     key:"pendingAmt",
        //     title:"Pending Amt"
        // },
        {
            key:"created_at",
            title:"Committed\nDate"
        },
        {
            key:"updated_at",
            title:"Payment Date\n/ Due Date"
        },
        {
            key:"refNo",
            title:"Transaction\nRef No",
        },
        {
            key:"click",
            title:"Complete Payment",
            onClick:(data)=>{
                    // navigate(`/marketplace/property/${data.propertyName}`,{state:{id:data._id}})
            }
        },
    ];


    const DetailButton = (props) =>{
        const getColor = () =>{
        if(props.elemProps.statusMap && props.elemProps.statusMap[props.children]){
            return props.elemProps.statusMap[props.children].color;
        }
        else return "success"
    }

        return(
            props.data.status == "CREATED" ?
            <Box sx={{
                padding:"8px 24px",
                bgcolor:`#fbf4e0`,
                color:`#975e0a`,
                borderRadius:"18px",
                width:"fit-content"
                }}>
                <Typography variant="body2">Payment Pending</Typography>
            </Box>

            :
            
            props.data.status == "INVESTORPAID" ? 

            <Box sx={{
                padding:"8px 24px",
                bgcolor:`#fbf4e0`,
                color:`#975e0a`,
                borderRadius:"18px",
                width:"fit-content"
                }}>
                <Typography variant="body2">Verification Pending</Typography>
            </Box>

            :
            
            (props.data.status == "APPROVED" && props?.data?.remAmt > 0) || props.data.status == "SECONDPENDING" ?
            <Button onClick={()=>{handleCompletePayment(props?.data?.remAmt);setCurrentTraxn(props?.data)}} variant="outlined">Complete Remaining Payment</Button>

            :

            props.data.status == "APPROVED" ?

            <Box sx={{
                padding:"8px 24px",
                bgcolor:`${getColor()}.light`,
                color:`${getColor()}.main`,
                borderRadius:"18px",
                width:"fit-content"
                }}>
                <Typography variant="body2">Payment Completed</Typography>
            </Box>

            :

            props.data.status == "FRAXTRANSFERED" ?

            <Box sx={{
                padding:"8px 24px",
                bgcolor:`${getColor()}.light`,
                color:`${getColor()}.main`,
                borderRadius:"18px",
                width:"fit-content"
                }}>
                <Typography variant="body2">Payment Completed</Typography>
            </Box>

            :

            props.data.status == "SECONDPARTINVESTORPAID" ? 
            
            <Box sx={{
            padding:"8px 24px",
            bgcolor:`#fbf4e0`,
            color:`#975e0a`,
            borderRadius:"18px",
            width:"fit-content"
            }}>
            <Typography variant="body2">Verification Pending</Typography>
            </Box>

            :
        
            props.data.status == "FRAXTRANSFERED" ? 
            
            <Box sx={{
                padding:"8px 24px",
                bgcolor:`${getColor()}.light`,
                color:`${getColor()}.main`,
                borderRadius:"18px",
                width:"fit-content"
                }}>
                <Typography variant="body2">Payment Completed</Typography>
            </Box>

            :
            
            <Box sx={{
            padding:"8px 24px",
            bgcolor:`#ffdfdf`,
            color:`#df0a0a`,
            borderRadius:"18px",
            width:"fit-content"
            }}>
            <Typography variant="body2">Payment Rejected</Typography>
            </Box>

        )
    }

    // const FraxRow = (props) =>{
    //     return(
    //         <Stack direction={"row"} spacing={3} alignItems={"center"}>
    //            <LinearProgress value={props.children} variant="determinate" color="success" sx={{height:9,width:120}}  /> 
    //            <Typography variant="body2">{props.children} %</Typography>
    //         </Stack>
    //     )
    // }
    const handleCompletePayment = (amount) =>{
        
        
        if(amount<=80000){
            setPaymentModeModal(true);
        }
        else{
            setPaymentModal(true);
        }
    }
    const handleBankTransfer = () =>{
        setPaymentModeModal(false);
        setPaymentModal(true);
    }
    
    const handlePayementGateway = async() =>{
        setPaymentModeModal(false);
        let reqData = {
            transactionId:currentTraxn._id,
            amount:currentTraxn.remAmt
        }
        // throw "abchdsh"
        const res = await transactionServices.createOrderRemAmt(reqData);
        // console.log(res);
        if(res && res.success){
            const order = res.data;
            const options = {
                key:order.notes.key,
                amount: order.amount,
                currency: "INR",
                name: user.name,
                description: "Purchase FRAX",
                order_id: order.id,
                // callback_url: `https://pupperazy-backend.odinflux.com/transaction/verifyOrder/${order.notes.transactionId}`,
                // callback_url: `http://192.168.0.114:5000/transaction/verifyOrder/${order.notes.transactionId}`,
                // callback_url: `http://192.168.0.109:80/transaction/verifyOrder/${order.notes.transactionId}`,
                // callback_url: `${process.env.REACT_APP_API_URL}transaction/verifyOrder/${order.notes.transactionId}`,
                // callback_url: `https://api.pupperazy.com/transaction/verifyOrder/${order.notes.transactionId}`,
                handler:(res)=>{
                    // console.log(res,"test");
                    enqueueSnackbar("Payment successfull.",{variant:"success",anchorOrigin:{horizontal:"center",vertical:"bottom"},autoHideDuration:2000})
                    // navigate("/portfolio")
                },
                prefill: {
                    name: user.name,
                    email: user.email,
                    contact: user.number
                },
                notes: {
                    "address": "REALX"
                },
                theme: {
                    "color": "#2F6BF2"
                },
                modal:{escape:false,
                    confirm_close:true,
                    ondismiss:()=>{
                    // console.log("fkdhf")
                }}
            };
            const razor = new window.Razorpay(options);
            razor.on('payment.failed', function (response){
                // console.log(response.error.code);
                // console.log(response.error.description);
                // console.log(response.error.source);
                // console.log(response.error.step);
                // console.log(response.error.reason);
                // console.log(response.error.metadata.order_id);
                // console.log(response.error.metadata.payment_id);
        });
            razor.open();
        }
        else{
            enqueueSnackbar(res.data.message,{variant:"error",anchorOrigin:{horizontal:"center",vertical:"bottom"},autoHideDuration:2000})
        }
    }
     const handleBankTransferSubmit = async() =>{
        let reqData = {
            transactionId:currentTraxn._id,
            amount:currentTraxn.remAmt
        }
        if(reqData){
            console.log(reqData);
            const req = await transactionServices.confirmSecondPartPayment(reqData);
            console.log(req);
            if(req && req.success){
                enqueueSnackbar("Success",{variant:"success"});
                getTransactionDetails();
                setPaymentModal(false)
            }
            else{
                if(req.data){
                    enqueueSnackbar(req.data.message,{variant:"error"});
                }
                else{
                    enqueueSnackbar("Something went wrong!!",{variant:"error"});
                }
            }
        }
    }

    const tableCells = [<TableRowImg />,<TableRowMultilinePropertyinfo /> ,<AmountPaidCommited/>,<TableRow />,<TableRow />,<TableRow />,<TableRow />,<TxnRefNumber/>,<DetailButton/>];

    if(data.length >0){
        return(
            <>
                <CompleteSecondPartPaymentModal open={paymentModal} handleClose={()=>setPaymentModal(false)} handleBankTransferSubmit={handleBankTransferSubmit} getTransactionDetails={getTransactionDetails} currentTransaction={currentTraxn}/>
                <PaymentMode open={paymentModeModal} handleClose={()=>setPaymentModeModal(false)} onBankTransfer={handleBankTransfer} onPaymentGateway={handlePayementGateway} />
                <CustomTable tableHeads={tableHeads} tableCells={tableCells} data={data} />
            </>
        )
    }
    else{
        return (<EmptyTableState showAction={false} title={"No Transactions Avaialble"} subtitle={""} />)
    }
}