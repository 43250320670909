import { Divider, Pagination, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import React, { cloneElement, useEffect, useMemo, useState } from "react";
import { filter } from 'lodash';


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return b[1] - a[1];
    });
    if (query) {
      return filter(array, (_user) => _user?.username && _user.username.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    }
    return stabilizedThis.map((el) => el[0]);
  }


export default function CustomTable({tableHeads=[],tableCells=[],data=[]}){

    const [page, setPage] = useState(0);
    const [count,setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [orderBy, setOrderBy] = useState('name');
    const [order, setOrder] = useState('asc');
   
    useEffect(()=>{
        setCount(data.length);
    },[data])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    

    const onClick =(event, data) =>{
        event.stopPropagation();

        if(tableHeads[tableHeads.length - 1] && tableHeads[tableHeads.length - 1].onClick)
        {
            tableHeads[tableHeads.length - 1].onClick(data);
        }
        
    }

    // const reversedData = useMemo(() => [...data].reverse(), [data]);

    const filteredTransactions = applySortFilter(data, getComparator(order, orderBy)) 
    const isUserNotFound = filteredTransactions.length === 0;
   

    return(
        <TableContainer >
            <Table>
                <TableHead>
                    <TableRow>
                        {
                            tableHeads.map((e,index)=><TableCell key={index} style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap',}}>{e.title}</TableCell>)
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                {filteredTransactions
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((e, index) => (
                    <>
                    <TableRow key={index} sx={{ cursor: "pointer", "&:hover":{bgcolor:'#f7f9f9'} }} onClick={() => onClick(e)}>
                        {tableCells.map((elem, i) => (
                            <TableCell key={i} sx={{ padding: '7px 7px !important', ...(i === 1 && { maxWidth: '150px', whiteSpace: 'normal', wordWrap: "break-word" }) }}>
                                {cloneElement(elem, { elemProps: tableHeads[i], data: e }, e[tableHeads[i].key])}
                            </TableCell>
                        ))}
                    </TableRow>
                    <TableRow>
                    <TableCell colSpan={tableHeads.length} sx={{ padding: '5px 12px !important' }}>
                        <Divider />
                    </TableCell>
                    </TableRow>
                   </>
                ))}
            </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ mt: 2, 
                    "& .css-1km9rea-MuiInputBase-root-MuiTablePagination-select":{minWidth:'50px'},
                    "& .css-1ayuxtp-MuiSelect-select-MuiInputBase-input":{bgcolor:'#f5f5f5',paddingLeft:'5px'},  
                 }}

            />       
            
         </TableContainer>
    )
}