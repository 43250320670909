import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
// import CircleIcon from '@mui/icons-material/Circle';
import Tabs from "./tabs/tabs";
import { useLocation, useNavigate } from "react-router-dom";
import { checkLogin, loggout } from "../utils/functions";
import { useDispatch, useSelector } from "react-redux";
import authServices from "../services/authServices";
import { saveUser } from "../redux/reducers/userSlice";
import RealxSelect from './modals/realxSelect';

export default function Header(){
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.data);
    const [activeTab,setActiveTab] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [width,setWidth] = useState(window.innerWidth);
    const { pathname } = useLocation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const isSelectUser =  user.membershiptype === "SELECT"? true: false;
    const logo = isSelectUser ? "/assets/images/realx_select.png" : '/assets/images/logo.png';

    const openModal = () => {
        setIsModalOpen(true);
      };
    
      const closeModal = () => {
        setIsModalOpen(false);
      };


    const tabs = [
        {
            name:"Dashboard",
            link:"/dashboard"
        },
        // {
        //     name:"Marketplace",
        //     link:"/marketplace",
        // },
        {
            name:"Portfolio",
            link:"/portfolio"
        },
        {
            name:"Transactions",
            link:"/transactions"
        },
    ]

    const openMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    useEffect(()=>{
        console.log(pathname,"----->Current route");
        console.log(tabs.findIndex((e)=>pathname.includes(e.link)),"----->Current tab");
        const index = tabs.findIndex((e)=>pathname.includes(e.link));
        if(index >= 0){
            setActiveTab(index);
        }
        if(!checkLogin()){
            navigate("/")
        }
     // eslint-disable-next-line
    },[pathname]);

    useEffect(()=>{
        window.addEventListener("resize",changeWidth);
        getUserData();
        return ()=>window.removeEventListener("resize",changeWidth);
        // eslint-disable-next-line
    },[])

    useEffect(() => {
        const button = document.getElementById('auto-ripple-button');
    
        const createRipple = (event) => {
          const ripple = document.createElement('span');
          ripple.classList.add('ripple');
          ripple.style.top = `${event.clientY - button.getBoundingClientRect().top}px`;
          ripple.style.left = `${event.clientX - button.getBoundingClientRect().left}px`;
          button.appendChild(ripple);
    
          setTimeout(() => {
            ripple.remove();
          }, 800); // Adjust the duration of the ripple effect as needed
        };
    
        button.addEventListener('click', createRipple);
    
        return () => {
          button.removeEventListener('click', createRipple);
        };
      }, []);
      
    const changeWidth = () =>{
        setWidth(window.innerWidth); 
    }

    const handleTabClick = (index) =>{
        setActiveTab(index);
        if(tabs[index]){
            navigate(tabs[index].link,{replace:true});
        }
    }

    const getUserData = async() =>{
        if(user && user._id){
            const res = await authServices.getUser(user._id);
            if(res && res.success && res.data && res.data.length > 0){
                console.log(res);
                dispatch(saveUser(res.data[0]));
            }
        }

    }


    return(
        <Box 
            sx={{
                // height:"100px",
                width:"100%",
                position:"absolute",
                top:0,
                bgcolor:"#FFF",
                px:{xs:"32px",md:"100px"},
                py:"20px",
            }}
        > 
              <RealxSelect
        open={isModalOpen}
        handleClose={closeModal}
        ownerType="Owner Type Value"
        propertyData="Property Data Value"
        handleSubmit={(amount) => {
          // Handle the amount submitted from the modal
          console.log(`Amount submitted: ${amount}`);
          closeModal();
        }}
      />

        <ProfileMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
            <Stack direction={"row"}>
                <Stack direction={"row"} spacing={"90px"} flex={1} alignItems={"center"}>
                    <Box onClick={()=>navigate("/dashboard")} sx={{height:{xs:24,md:54},cursor:"pointer"}} component={"img"} src={logo}></Box>
                    {
                        width > 900 ?
                        <Tabs activeTab={activeTab} tabs={tabs} onClick={handleTabClick} />
                        :""
                    }
                </Stack>
                <Stack direction={"row"} spacing={5} alignItems={"center"}>
                    <div>
                <Button 
                        spacing={3}
                        id="auto-ripple-button"
                        onClick={()=>openModal(true)}
                        sx={{ mb: { md: 0, xs: 3 }, marginRight: '20px' ,  position: 'relative',
                        display: isSelectUser ? 'none' : 'block',
                        overflow: 'hidden'}} // Add marginRight here
                        variant="contained"
                    >Join Select</Button>
                </div>
                    </Stack>

                <Stack direction={"row"} spacing={5} alignItems={"center"}>
                    <Stack direction={"row"} spacing={3} alignItems={"center"}>
                        <Stack sx={{cursor:"pointer",maxWidth:"130px"}} direction={"row"} alignItems={"center"} spacing={3}>
                                <Box component={"img"} src={"/assets/images/landing/user.png"} width={44} height={44} />
                                <Typography variant='body2' sx={{overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>{user?.username || "User"}</Typography>
                            </Stack>
                        </Stack>
                    {/* <Box sx={{width:"1px",height:"60px",bgcolor:"#A4A4A4",mt:"-2px !important"}}></Box> */}
                    <MenuIcon sx={{cursor:"pointer"}} onClick={openMenu} fontSize="medium" />
                </Stack>
            </Stack>
        </Box>
    )
}

const ProfileMenu = ({anchorEl,setAnchorEl}) =>{

    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (link) =>{
        navigate(link);
        setAnchorEl(null);
    }

    const handleLoggout = () =>{
        navigate("/");
        loggout();
        setAnchorEl(null);
    }
    return(
        <Menu
        PaperProps={{
            sx:{
                minWidth:"fit-content !important"
            }
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={()=>handleClick("/dashboard/profile")}>
            <Stack direction={"row"} spacing={1.5}>
                {/* <CircleIcon sx={{width:16,height:16}} /> */}
                <Typography variant="body2">My Profile</Typography>
            </Stack>
        </MenuItem>
        {/* <MenuItem onClick={handleClose}>
            <Stack direction={"row"} spacing={1.5}>
                <Typography variant="body2">Transactions</Typography>
            </Stack>
        </MenuItem> */}
        {/* <MenuItem onClick={handleClose}>
            <Stack direction={"row"} spacing={1.5}>
                <CircleIcon sx={{width:16,height:16}} />
                <Typography variant="body2">Nominees</Typography>
            </Stack>
        </MenuItem> */}
        {/* <MenuItem onClick={()=>handleClick("/about-us")}>
            <Stack direction={"row"} spacing={1.5}>
                <Typography variant="body2">About us</Typography>
            </Stack>
        </MenuItem> */}
        {/* <MenuItem onClick={()=>handleClick("/how-it-works")}>
            <Stack direction={"row"} spacing={1.5}>
                <Typography variant="body2">How it works</Typography>
            </Stack>
        </MenuItem> */}
        {/* <MenuItem onClick={()=>handleClick("/why-realx")}>
            <Stack direction={"row"} spacing={1.5}>
                <Typography variant="body2">Why RealX</Typography>
            </Stack>
        </MenuItem> */}
        {/* <MenuItem onClick={()=>handleClick("/faq")}>
            <Stack direction={"row"} spacing={1.5}>
                <Typography variant="body2">FAQs</Typography>
            </Stack>
        </MenuItem> */}
        {/* <MenuItem onClick={()=>handleClick("/policy")}>
            <Stack direction={"row"} spacing={1.5}>
                <Typography variant="body2">Privacy Policy</Typography>
            </Stack>
        </MenuItem> */}
        <MenuItem onClick={handleLoggout}>
            <Stack direction={"row"} spacing={1.5}>
                {/* <CircleIcon sx={{width:16,height:16}} /> */}
                <Typography variant="body2">Logout</Typography>
            </Stack>
        </MenuItem>
        
      </Menu>
    )
}