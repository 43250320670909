import { Box, Button, Stack, Typography, Grid, CircularProgress, Backdrop } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import { LoadingButton } from "@mui/lab"
import React, { useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from "formik";
import Lottie from "lottie-react";
import { FormDatePicker } from "../form/datePicker";
import FormField from "../form/formField";
import FormFileField from "../form/formFileField";
import FormSelect from "../form/formSelect";
import ModalComponent from "./modalComponent";
import addressServices from "../../services/addressServices";
import authServices from "../../services/authServices";
import { useDispatch, useSelector } from 'react-redux';
import CustomIcon from "../customIcon";
import FormAutoCompleted from "../form/formAutoComplete";
import bankServices from './../../services/bankServices';
import { useSnackbar } from "notistack";
import { saveUser } from "../../redux/reducers/userSlice";
import check from "../../animations/check.json"
import moment from "moment";
import { PhoneInput } from 'react-international-phone';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import 'react-international-phone/style.css';
import AreYouSureModal from "./areYouSureModal";

const VALID_FILE_SIZE = 5000000;

const SUPPORTED_FORMATS = ["image/png", "image/jpeg", "application/pdf"];

const personalInfoSchemaThroughPhoneNumber = Yup.object().shape({
    username: Yup.string().required("Username is required."),
    emailId: Yup.string().email("Please enter valid email.").required("Email is required."),
    // gender:Yup.string().required("Gender is required."),
    dob: Yup.string().required("Date of birth is required."),
    citizenShip: Yup.string().required("Citizenship is required."),
    // address:Yup.object().shape({
    //     pinCode:Yup.number().required("This field is required."),
    //     house:Yup.string().required("This field is required."),
    //     area:Yup.string().required("This field is required."),
    //     landMark:Yup.string().required("This field is required."),
    //     city:Yup.string().required("This field is required."),
    //     state:Yup.string().required("This field is required."),
    // })
});

const personalInfoSchemaThroughSocialSignIn = Yup.object().shape({
    username: Yup.string().required("Username is required."),
    // gender:Yup.string().required("Gender is required."),
    dob: Yup.string().required("Date of birth is required."),
    citizenShip: Yup.string().required("Citizenship is required."),
    // address:Yup.object().shape({
    //     pinCode:Yup.number().required("This field is required."),
    //     house:Yup.string().required("This field is required."),
    //     area:Yup.string().required("This field is required."),
    //     landMark:Yup.string().required("This field is required."),
    //     city:Yup.string().required("This field is required."),
    //     state:Yup.string().required("This field is required."),
    // })
});

const kycSchema = Yup.object().shape({
    panCardNumber: Yup.string().required("PAN No. is required."),
    ifscCode: Yup.string().required("IFSC code is required."),
    accNumber: Yup.number().required("Account Number is required."),
    // aadhaar:Yup.string().required("Aadhaar No. is required.")
    // addressProof:Yup.object().nullable().required("Please select address proof."),
    // bankInformation:Yup.object().shape({
    //     ifsc:Yup.string().required("IFSC code is required."),
    //     accHolderName:Yup.string().required("Name is required."),
    //     accNumber:Yup.number().required("Account Number is required."),
    //     bank:Yup.string(),
    //     branch:Yup.string(),
    //     accType:Yup.string().required("Account Type is required."),
    // }),
    // panCard:Yup.mixed().nullable().required("Please upload this document.").test("FILE_SIZE", "Upload a file below 5MB.", value => value && value.size <= VALID_FILE_SIZE)
    //     .test("FILE_FORMAT", "Uploaded file has unsupported format.", value => value && SUPPORTED_FORMATS.includes(value.type)),
    // bankCheque:Yup.mixed().nullable().required("Please upload this document.").test("FILE_SIZE", "Upload a file below 5MB.", value => value && value.size <= VALID_FILE_SIZE)
    // .test("FILE_FORMAT", "Uploaded file has unsupported format.", value => value && SUPPORTED_FORMATS.includes(value.type)),
    // addressProofFront:Yup.mixed().nullable().required("Please upload this document.").test("FILE_SIZE", "Upload a file below 5MB.", value => value && value.size <= VALID_FILE_SIZE)
    //     .test("FILE_FORMAT", "Uploaded file has unsupported format.", value => value && SUPPORTED_FORMATS.includes(value.type)),
    // addressProofBack:Yup.mixed().nullable().test("FILE_SIZE", "Upload a file below 5MB.", value => value ? value.size <= VALID_FILE_SIZE:true)
    //     .test("FILE_FORMAT", "Uploaded file has unsupported format.", value => value ? SUPPORTED_FORMATS.includes(value.type):true),
})

const manualKycSchema = Yup.object().shape({
    // panCardNumber: Yup.string().required("PAN No. is required."),
    // ifscCode: Yup.string().required("IFSC code is required."),
    // accNumber: Yup.number().required("Account Number is required."),
    // aadhaar:Yup.string().required("Aadhaar No. is required.")
    addressProof:Yup.object().nullable().required("Please select address proof."),
    bankInformation:Yup.object().shape({
        ifsc:Yup.string().required("IFSC code is required."),
        accHolderName:Yup.string().required("Name is required."),
        accNumber:Yup.number().required("Account Number is required."),
        bank:Yup.string(),
        branch:Yup.string(),
        accType:Yup.string().required("Account Type is required."),
    }),
    userAddress: Yup.object().shape({
        flatNo: Yup.string().required("Flat No is required."),
        area: Yup.string().required("Atea is required."),
        landmark: Yup.string(),
        town: Yup.string().required("Town is required."),
        state: Yup.string().required("State is required."),
        pincode: Yup.string().required("Pincode is required."),
    }),
    panCard:Yup.mixed().nullable().required("Please upload this document.").test("FILE_SIZE", "Upload a file below 5MB.", value => value && value.size <= VALID_FILE_SIZE)
        .test("FILE_FORMAT", "Uploaded file has unsupported format.", value => value && SUPPORTED_FORMATS.includes(value.type)),
    bankCheque:Yup.mixed().nullable().required("Please upload this document.").test("FILE_SIZE", "Upload a file below 5MB.", value => value && value.size <= VALID_FILE_SIZE)
    .test("FILE_FORMAT", "Uploaded file has unsupported format.", value => value && SUPPORTED_FORMATS.includes(value.type)),
    addressProofFront:Yup.mixed().nullable().required("Please upload this document.").test("FILE_SIZE", "Upload a file below 5MB.", value => value && value.size <= VALID_FILE_SIZE)
        .test("FILE_FORMAT", "Uploaded file has unsupported format.", value => value && SUPPORTED_FORMATS.includes(value.type)),
    addressProofBack:Yup.mixed().nullable().test("FILE_SIZE", "Upload a file below 5MB.", value => value ? value.size <= VALID_FILE_SIZE:true)
        .test("FILE_FORMAT", "Uploaded file has unsupported format.", value => value ? SUPPORTED_FORMATS.includes(value.type):true),
})

export default function AccountSetupModal({ open, handleClose = () => { } }) {
    const [currentStep, setStep] = useState(1);
    const [blockchainAccountDownload, setBlockchainAccountDownload] = useState(false);
    const [blockchainAccountCreated, setBlockchainAccountCreated] = useState(false);
    const [animating, setAnimating] = useState(false);
    const [mnemonic, setmnemonic] = useState();
    const [otp, setOtp] = useState("");
    const [dltName, setDltName] = useState("");
    const [otpForm, setOtpForm] = useState(false);
    const [aadhaar, setAadhaar] = useState("");
    const [refId, setRefId] = useState("");
    const [openProgressBar, setOpenProgressBar] = useState(false);
    const modelRef = useRef();
    const [encryptedMnemonic, setEncryptedMnemonic] = useState("");
    const [sendMail, setSendMail] = useState(true);
    const user = useSelector(state => state.user.data);
    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useDispatch();

    useEffect(() => {
        console.log(user?.status);
        if (user?.status === "FIRST_TIME") setStep(1);
        if (user?.status === "REGISTERED" || user?.status === "REJECTED") setStep(2);
        if (user?.status === "KYC_APPROVED") setStep(3);
    }, [user?.status]);

    useEffect(() => {
        if (animating) {
            scrollToTop();
        }
    }, [animating])

    const customHandleClose = () => {
        if (currentStep !== 3 || !blockchainAccountCreated || blockchainAccountDownload) handleClose();
        else enqueueSnackbar("Please download key details before closing!", { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }, autoHideDuration: 5000 })
        if (sendMail && blockchainAccountDownload) sendMnemonicThroughMail();
    }

    const scrollToTop = () => {
        if (modelRef.current) {
            let modalBody = modelRef.current.querySelector('.MuiDialog-container')
            // console.log(modalBody)
            if (modalBody) {
                modalBody.scroll({
                    top: 0,
                    behavior: 'smooth'
                });
            }
        }
    }

    const sendMnemonicThroughMail = async () => {
        await authServices.sendMnemonicThroughMail({ data: user.walletInfo.publicAddress + "|" + encryptedMnemonic, mnemonic, userId: user._id });
    }
    return (
        <ModalComponent slotProps={{
            root: {
                ref: modelRef
            }
        }}
            open={open}
            handleClose={customHandleClose}
            title={"Complete Account Setup"} PaperProps={{ sx: { overflow: "auto", minHeight:'auto', minWidth: { md: 684, xs: "auto" } } }}>
            <Backdrop open={openProgressBar} sx={{ color: "#ddd", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Box
                    sx={{
                        position: "absolute",
                        inset: 0,
                        zIndex: (theme) => theme.zIndex.modal + 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <CircularProgress
                        size={75}
                        thickness={4}
                        
                    />
                </Box>
            </Backdrop>
            <Stepper currentStep={currentStep} />
            {
                animating &&
                <Box sx={{ width: 150, margin: "auto", pt: 20 }}>
                    <Lottie onComplete={() => {
                        setAnimating(false);
                    }} animationData={check} loop={false} />
                </Box>

            }
            {
                currentStep === 1 && !animating ?
                    <PersonalInfo setStep={setStep} user={user} enqueueSnackbar={enqueueSnackbar} dispatch={dispatch} setAnimating={setAnimating} />
                    :
                    currentStep === 2 && !animating ?
                        <KYCForm setStep={setStep} user={user} otp={otp} refId={refId} setRefId={setRefId} setOtp={setOtp} otpForm={otpForm} setOtpForm={setOtpForm} aadhaar={aadhaar} setAadhaar={setAadhaar} openProgressBar={openProgressBar} setOpenProgressBar={setOpenProgressBar} enqueueSnackbar={enqueueSnackbar} dispatch={dispatch} handleClose={handleClose} setAnimating={setAnimating} />
                        :
                        currentStep === 3 && !animating ?
                            <BlockchainAcc mnemonic={mnemonic} sendMail={sendMail} setSendMail={setSendMail} setEncryptedMnemonic={setEncryptedMnemonic} setmnemonic={setmnemonic} setStep={setStep} user={user} setBlockchainAccountCreated={setBlockchainAccountCreated} setBlockchainAccountDownload={setBlockchainAccountDownload} enqueueSnackbar={enqueueSnackbar} dispatch={dispatch} customHandleClose={customHandleClose} setAnimating={setAnimating} dltName={dltName} setDltName={setDltName}/>
                            : ""
            }
        </ModalComponent>
    )
}

const Stepper = ({ currentStep }) => {
    const steps = ["Create Account", "Personal Details", "Complete KYC", "Create Your Digital Assets Account"];
    return (
        <Box mt={5}>
            <Stack direction={"row"} spacing={4}>
                {
                    steps.map((e, i) => {
                        return (
                            <Stack direction={"column"}>
                                <Box sx={{
                                    width: "100%",
                                    position: "relative"
                                }}>
                                    <Box sx={{
                                        width: 24, height: 24,
                                        borderRadius: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        bgcolor: currentStep > i ? "success.main" : "success.light",
                                        position: "relative", zIndex: 1
                                    }}>
                                        {
                                            currentStep === i ?
                                                <Box sx={{
                                                    width: 12,
                                                    height: 12,
                                                    bgcolor: "success.main",
                                                    borderRadius: "100%",
                                                }}>
                                                </Box>
                                                :
                                                currentStep > i ?
                                                    <CustomIcon name="check" sx={{ bgcolor: "#FFF", width: 12, height: 12, }} />
                                                    : ""
                                        }
                                    </Box>
                                    {
                                        steps.length === i + 1 ? "" :
                                            <Box sx={{
                                                width: "calc(100% + 32px)",
                                                height: 4,
                                                bgcolor: currentStep > i ? "success.main" : "success.light",
                                                position: "absolute",
                                                top: "50%",
                                                left: 0,
                                                transform: "translateY(-50%)",
                                                zIndex: 0
                                            }}>
                                            </Box>
                                    }
                                </Box>
                                <Box sx={{ minWidth: 130, display: { xs: currentStep === i ? "block" : "none", md: "block" } }}>
                                    <Typography variant="subtitle1" sx={{ fontSize: 12, mt: { md: 3, xs: 2 } }}>Step {i + 1}</Typography>
                                    <Typography variant="body2" sx={{ mt: 0 }}>{e}</Typography>
                                </Box>
                            </Stack>
                        )
                    })
                }
                {/* <Stack direction={"column"}>
                    <Box sx={{width:24,height:24,borderRadius:"100%",bgcolor:"success.main"}}></Box>
                    <Box sx={{minWidth:130}}>
                        <Typography variant="subtitle1" sx={{fontSize:12,mt:3}}>Step 2</Typography>
                        <Typography variant="body2" sx={{mt:0}}>Complete KYC</Typography>
                    </Box>
                </Stack> */}
            </Stack>
        </Box>
    )
}

const PersonalInfo = ({ setStep, user, enqueueSnackbar, dispatch, setAnimating }) => {

    const [phone, setPhone] = useState("")
    const [country, setCountry] = useState("")
    const [isPhoneValid, setIsPhoneValid] = useState(true)

    // const genderOptions = [
    //     // {
    //     //     title:"Male",
    //     //     value:"Male",
    //     // },
    //     // {
    //     //     title:"Female",
    //     //     value:"Female",
    //     // },
    //     // {
    //     //     title:"Others",
    //     //     value:"Others",
    //     // },
    //     {
    //         title:"Mr (He/His)",
    //         value:"Mr (He/His)",
    //     },
    //     {
    //         title:"Mrs (She/Her)",
    //         value:"Mrs (She/Her)",
    //     },
    //     {
    //         title:"Ms (She/Her)",
    //         value:"Ms (She/Her)",
    //     },
    //     {
    //         title:"Dr. (He/His)",
    //         value:"Dr. (He/His)",
    //     },
    //     {
    //         title:"Dr. (She/Her)",
    //         value:"Dr. (She/Her)",
    //     },
    //     {
    //         title:"I'd rather not say",
    //         value:"Others",
    //     }
    // ];

    const citizenshipOptions = [
        {
            title: "Indian",
            value: "INDIAN",
        },
    ];

    // const stateOptions =[
    //     {
    //         "title": "Andhra Pradesh",
    //         "value": "Andhra Pradesh"
    //     },
    //     {
    //         "title": "Arunachal Pradesh",
    //         "value": "Arunachal Pradesh"
    //     },
    //     {
    //         "title": "Assam",
    //         "value": "Assam"
    //     },
    //     {
    //         "title": "Bihar",
    //         "value": "Bihar"
    //     },
    //     {
    //         "title": "Chandigarh (UT)",
    //         "value": "Chandigarh (UT)"
    //     },
    //     {
    //         "title": "Chhattisgarh",
    //         "value": "Chhattisgarh"
    //     },
    //     {
    //         "title": "Dadra and Nagar Haveli (UT)",
    //         "value": "Dadra and Nagar Haveli (UT)"
    //     },
    //     {
    //         "title": "Daman and Diu (UT)",
    //         "value": "Daman and Diu (UT)"
    //     },
    //     {
    //         "title": "Delhi",
    //         "value": "Delhi"
    //     },
    //     {
    //         "title": "Goa",
    //         "value": "Goa"
    //     },
    //     {
    //         "title": "Gujarat",
    //         "value": "Gujarat"
    //     },
    //     {
    //         "title": "Haryana",
    //         "value": "Haryana"
    //     },
    //     {
    //         "title": "Himachal Pradesh",
    //         "value": "Himachal Pradesh"
    //     },
    //     {
    //         "title": "Jammu and Kashmir",
    //         "value": "Jammu and Kashmir"
    //     },
    //     {
    //         "title": "Jharkhand",
    //         "value": "Jharkhand"
    //     },
    //     {
    //         "title": "Karnataka",
    //         "value": "Karnataka"
    //     },
    //     {
    //         "title": "Kerala",
    //         "value": "Kerala"
    //     },
    //     {
    //         "title": "Lakshadweep (UT)",
    //         "value": "Lakshadweep (UT)"
    //     },
    //     {
    //         "title": "Madhya Pradesh",
    //         "value": "Madhya Pradesh"
    //     },
    //     {
    //         "title": "Maharashtra",
    //         "value": "Maharashtra"
    //     },
    //     {
    //         "title": "Manipur",
    //         "value": "Manipur"
    //     },
    //     {
    //         "title": "Meghalaya",
    //         "value": "Meghalaya"
    //     },
    //     {
    //         "title": "Mizoram",
    //         "value": "Mizoram"
    //     },
    //     {
    //         "title": "Nagaland",
    //         "value": "Nagaland"
    //     },
    //     {
    //         "title": "Odisha",
    //         "value": "Odisha"
    //     },
    //     {
    //         "title": "Puducherry (UT)",
    //         "value": "Puducherry (UT)"
    //     },
    //     {
    //         "title": "Punjab",
    //         "value": "Punjab"
    //     },
    //     {
    //         "title": "Rajasthan",
    //         "value": "Rajasthan"
    //     },
    //     {
    //         "title": "Sikkim",
    //         "value": "Sikkim"
    //     },
    //     {
    //         "title": "Tamil Nadu",
    //         "value": "Tamil Nadu"
    //     },
    //     {
    //         "title": "Telangana",
    //         "value": "Telangana"
    //     },
    //     {
    //         "title": "Tripura",
    //         "value": "Tripura"
    //     },
    //     {
    //         "title": "Uttarakhand",
    //         "value": "Uttarakhand"
    //     },
    //     {
    //         "title": "Uttar Pradesh",
    //         "value": "Uttar Pradesh"
    //     },
    //     {
    //         "title": "West Bengal",
    //         "value": "West Bengal"
    //     }
    // ];

    const handlePhoneChange = (value, country) => {
        // Parse phone number
        const phoneNumber = parsePhoneNumberFromString(value);

        // Extracting the mobile number without the dial code
        const mobileNumber = phoneNumber ? phoneNumber.nationalNumber : '';

        setPhone(mobileNumber)
        setCountry(country.country.dialCode)
        setIsPhoneValid(true)
    };

    const formik = useFormik({
        initialValues: {
            username: "",
            emailId: "",
            phone: "",
            dob: moment().subtract(18, "years"),
            // gender:"",
            citizenShip: "",
            // address:{
            //     pinCode:"",
            //     house:"",
            //     area:"",
            //     landMark:"",
            //     city:"",
            //     state:""
            // },
        },
        validationSchema: user.signInType === "phone" ? personalInfoSchemaThroughPhoneNumber : personalInfoSchemaThroughSocialSignIn,
        onSubmit: (v) => {
            saveDetails(v);
        },
    });


    const { errors, touched, handleSubmit, getFieldProps, values, setFieldValue, isSubmitting, setSubmitting } = formik;

    const saveDetails = async (values) => {
        if (user.signInType !== "phone" && !phone) {
            setIsPhoneValid(false)
            return
        }
        let data = {
            ...values,
            dob: values.dob.unix(),
            investorId: user._id,
            status: "REGISTERED"
        };
        if (user.signInType !== "phone") {
            delete data['email']
            data['phone'] = parseInt(phone)
            data['countryCode'] = parseInt(country)
        }

        setSubmitting(true);
        const res = await authServices.savePersonalDetails(data);
        setSubmitting(false);
        if(res.data.code===409)
        {
            enqueueSnackbar(res.data.message, { variant: "error" });
        }else if (res && res.success) {
            enqueueSnackbar("Personal details submitted", { variant: "success" });
            setAnimating(true)
            dispatch(saveUser(res.data));
            // setStep(1);
        } else enqueueSnackbar(res?.data?.message || "Something went wrong!", { variant: "error", autoHideDuration: 4000});
    }
    // const getAddressFromPinCode =  async() =>{
    //     const res = await addressServices.getAddressFromPinCode(values?.address?.pinCode);
    //     if(res && res.PostOffice && res.PostOffice[0]){
    //         console.log(res.PostOffice[0])
    //         setFieldValue("address.city",res.PostOffice[0]?.District)
    //         setFieldValue("address.state",res.PostOffice[0]?.State)
    //     }
    // }
    console.log(errors);
    return (
        <Box mt={4}>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Typography variant="h4">Enter Personal Info</Typography>
                    <Stack spacing={1.5} mt={3}>
                        <FormField
                            label={"How would you like to be called? *"}
                            inputProps={{
                                placeholder: "username",
                                ...getFieldProps("username"),
                                error: Boolean(touched.username && errors.username),
                                helperText: touched.username && errors.username
                            }}
                        />

                        <Stack direction={{ md: "row", xs: "column" }} spacing={{ md: 3, xs: 1.5 }}>
                            {user?.signInType === "phone" ?
                                <Box width={{ md: "50%", xs: "100%" }}>
                                    <FormField
                                        label={"Email Address *"}
                                        inputProps={{
                                            placeholder: "",
                                            ...getFieldProps("emailId"),
                                            error: Boolean(touched.emailId && errors.emailId),
                                            helperText: touched.emailId && errors.emailId
                                        }}
                                    />
                                </Box>
                                :
                                <Box width={{ md: "50%", xs: "100%" }}>
                                    <Stack direction={"column"} spacing={1}>
                                        <Typography variant="label">Phone Number *</Typography>
                                        <PhoneInput
                                            defaultCountry="in"
                                            value={0}
                                            onChange={(phone, country) => handlePhoneChange(phone, country)}
                                            inputStyle={{
                                                padding: "24px 12px",
                                                width: "100%"
                                            }}
                                        />
                                        {!isPhoneValid && (
                                            <Typography variant="label" style={{
                                                color: "#FF4842",
                                                fontSize: "12px",
                                                margin: "3px 14px 0px"
                                            }}>Phone Number is required</Typography>
                                        )}
                                    </Stack>
                                </Box>
                            }
                            <Box width={{ md: "50%", xs: "100%" }}>

                                <FormSelect label={"Citizenship *"} options={citizenshipOptions}
                                    props={{
                                        placeholder: "-- select --",
                                        ...getFieldProps("citizenShip"),
                                        error: Boolean(touched.citizenShip && errors.citizenShip),
                                        helperText: (touched.citizenShip && errors.citizenShip) || "*Restricted to indian nationals only",
                                        renderValue: values.citizenShip !== "" ? undefined : () => <Typography variant="placeHolder" sx={{ color: "text.disabled", fontSize: 14, }}>Select</Typography>
                                    }}
                                />
                            </Box>
                            {/* <FormSelect label={"How should we address you? *"} options={genderOptions} 
                                props ={{
                                    placeholder:"select",
                                    ...getFieldProps("gender"),
                                    error:Boolean(touched.gender && errors.gender),
                                    helperText:touched.gender && errors.gender,
                                    renderValue:values.gender !== "" ? undefined :()=><Typography variant="placeHolder" sx={{color:"text.disabled",fontSize:14,}}>Select</Typography>
                                }}
                            /> */}
                        </Stack>
                        <Stack direction={{ md: "row", xs: "column" }} spacing={{ md: 3, xs: 1.5 }}>
                            <FormDatePicker label={"Date Of Birth *"}
                                props={{
                                    // onChange:()=>{},
                                    disabledFuture: true,
                                    value: values.dob,
                                    onChange: (newValue) => {
                                        // console.log(newValue);
                                        setFieldValue("dob", newValue);
                                    }
                                }}
                            />
                        </Stack>
                    </Stack>
                    {/* <Typography mt={5} variant="h4">Address Info *</Typography>
                    <Stack spacing={1.5} mt={3}>
                    <Stack direction={{md:"row",xs:"column"}} spacing={{md:3,xs:1.5}}>
                            <FormField 
                                label={"Pincode *"}
                                inputProps={{
                                    placeholder:"",
                                    ...getFieldProps("address.pinCode"),
                                    onBlur:getAddressFromPinCode,
                                    error:Boolean(touched.address?.pinCode && errors.address?.pinCode),
                                    helperText:touched.address?.pinCode && errors.address?.pinCode,
                                }}
                            />
                        </Stack>
                        <FormField 
                            label={"Flat No., House No., Company, Apartment *"}
                            inputProps={{
                                placeholder:"",
                                ...getFieldProps("address.house"),
                                error:Boolean(touched.address?.house && errors.address?.house),
                                helperText:touched.address?.house && errors.address?.house,
                            }}
                        />
                        <FormField 
                            label={"Area, Street, Sector, Village *"}
                            inputProps={{
                                placeholder:"",
                                ...getFieldProps("address.area"),
                                error:Boolean(touched.address?.area && errors.address?.area),
                                helperText:touched.address?.area && errors.address?.area,
                            }}
                        />
                        <FormField 
                            label={"Landmark*"}
                            inputProps={{
                                placeholder:"(Eg. Near Apollo Hospital)",
                                ...getFieldProps("address.landMark"),
                                error:Boolean(touched.address?.landMark && errors.address?.landMark),
                                helperText:touched.address?.landMark && errors.address?.landMark,
                            }}
                        />
                        <Stack direction={{md:"row",xs:"column"}} spacing={{md:3,xs:1.5}}>
                            <FormField 
                                label={"Town/City *"}
                                inputProps={{
                                    placeholder:"",
                                    ...getFieldProps("address.city"),
                                    error:Boolean(touched.address?.city && errors.address?.city),
                                    helperText:touched.address?.city && errors.address?.city,
                                }}
                            />
                            <FormSelect label={"State *"} options={stateOptions} 
                                props ={{
                                    ...getFieldProps("address.state"),
                                    error:Boolean(touched.address?.state && errors.address?.state),
                                    helperText:touched.address?.state && errors.address?.state,
                                    renderValue:values.address?.state !== "" ? undefined :()=><Typography variant="placeHolder" sx={{color:"text.disabled"}}>Select State</Typography>
                                }}
                            />
                        </Stack>
                    </Stack> */}
                    <LoadingButton loading={isSubmitting} type="submit" 
                     variant="contained"
                     sx={{mt: 5, width: { md: "auto", xs: "100%" } }}
                     loadingIndicator={
                        <CircularProgress size={30} sx={{ color: 'red' }} /> 
                      }
                    >
                        Save And proceed
                    </LoadingButton>
                </Form>
            </FormikProvider>
        </Box>
    )
}


const KYCForm = ({ setStep, user, enqueueSnackbar, dispatch, handleClose, setAnimating, otp, setOtp, otpForm, refId, setRefId, setOtpForm, aadhaar, setAadhaar, openProgressBar, setOpenProgressBar }) => {
    
    const [isManual, setIsManual] = useState(false);

    return (
      <Box>
        {!isManual && <Stack>
            <AutoKYCForm setIsManual={setIsManual} setStep={setStep} user={user} otp={otp} refId={refId} setRefId={setRefId} setOtp={setOtp} otpForm={otpForm} setOtpForm={setOtpForm} aadhaar={aadhaar} setAadhaar={setAadhaar} openProgressBar={openProgressBar} setOpenProgressBar={setOpenProgressBar} enqueueSnackbar={enqueueSnackbar} dispatch={dispatch} handleClose={handleClose} setAnimating={setAnimating} />
        </Stack>}
        {isManual && <Stack>
            <ManualKYCForm setIsManual={setIsManual} setStep={setStep} user={user} otp={otp} refId={refId} setRefId={setRefId} setOtp={setOtp} otpForm={otpForm} setOtpForm={setOtpForm} aadhaar={aadhaar} setAadhaar={setAadhaar} openProgressBar={openProgressBar} setOpenProgressBar={setOpenProgressBar} enqueueSnackbar={enqueueSnackbar} dispatch={dispatch} handleClose={handleClose} setAnimating={setAnimating} />
        </Stack>}
      </Box>  
    );
}

const AutoKYCForm = ({ setIsManual, setStep, user, enqueueSnackbar, dispatch, handleClose, setAnimating, otp, setOtp, otpForm, refId, setRefId, setOtpForm, aadhaar, setAadhaar, openProgressBar, setOpenProgressBar }) => {

    const [showBranch, setShowBranch] = useState("");
    const [aadhaarData, setAadhaarData] = useState({});
    const [isAdharVerified, setAdharVerified] = useState(false);
    const [isShowOPT, setOTPSection] = useState(false);
    const [validation, setValidation] = React.useState(kycSchema);
    const userId = useSelector((state)=>state.user.data._id)

    // const addressProofOptions=[
    //     {
    //         title:"Aadhar Card",
    //         label:"Aadhar Card",
    //         value:"Aadhar Card",
    //         back:true
    //     },
    //     {
    //         title:"Voter Card",
    //         label:"Voter Card",
    //         value:"Voter Card",
    //         back:false
    //     },
    //     {
    //         title:"Passport",
    //         label:"Passport",
    //         value:"Passport",
    //         back:true
    //     },
    //     {
    //         title:"Driving license",
    //         label:"Driving license",
    //         value:"Driving license",
    //         back:false
    //     },
    // ];

    // const accTypeOptions = [
    //     {
    //         title: "Current account",
    //         value: "Current account",
    //     },
    //     {
    //         title: "Saving account",
    //         value: "Saving account",
    //     },
    // ]

    const getBankFromIFSC = async () => {
        console.log(values?.bankInformation?.ifsc);
        if (values?.bankInformation?.ifsc) {
            const res = await bankServices.getBankFromIFSCCode(values?.bankInformation?.ifsc);
            if (res) {
                console.log(res)
                setShowBranch(`${res.BANK}, ${res.BRANCH}`);
                setFieldValue("bankInformation.bank", res.BANK)
                setFieldValue("bankInformation.branch", res.BRANCH)
            }
            else {
                setShowBranch("Not Found");
                setFieldError("bankInformation.ifsc", "Not Found")
            }
        }
    }

    const submitAadhaar = async () => {
        const finalAadhaarNumber = aadhaarValues.join("");
        if (finalAadhaarNumber.length !== 12) {
            setOTPSection(false);
            enqueueSnackbar("Invalid Aadhaar Number", { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }, autoHideDuration: 5000 })
            return;
        } else {
            setAadhaar(finalAadhaarNumber);
        }
        
        // Your logic to handle submission
        setOpenProgressBar(true);

        const res = await authServices.submitAadhaar({ aadharNo: finalAadhaarNumber });
        setOpenProgressBar(false);
        if (res && res.success) {
            setRefId(res.data.reference_id);
            setOTPSection(true);
            enqueueSnackbar("Aadhaar Number submitted successfully", { variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" }, autoHideDuration: 800 })
        }
        else {
            setOTPSection(false);
            enqueueSnackbar("Aadhar Number Not Found!", { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }, autoHideDuration: 5000 })
        }
    }

    const verifyAadhaar = async () => {
        setOpenProgressBar(true);
        const finalAadhaarNumber = aadhaarValues.join("");
        const res = await authServices.verifyAadhaar({ aadhaar_otp: otp, reference_id: refId, userId:userId, aadharNo: finalAadhaarNumber })
        setOpenProgressBar(false);
        if (res && res.success) {
            setAadhaarData(res.data);
            console.log(aadhaarData);
            console.log("asdasda");
            console.log(res.data);
            setAdharVerified(true);
            enqueueSnackbar("OTP Verified Successfully", { variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" }, autoHideDuration: 800 })
        }
        else {
            setAdharVerified(false);
            enqueueSnackbar("Invalid OTP, Please check again!", { variant: "error", anchorOrigin: { horizontal: "right", vertical: "top" }, autoHideDuration: 4000 })
        }
    }

    const formik = useFormik({
        initialValues: {
            panCardNumber: "",
            ifscCode: "",
            accNumber: ""
            // addressProof:"",
            // panCard:null,
            // bankCheque:null,
            // addressProofFront:null,
            // addressProofBack:null,
            // bankInformation:{
            //     ifsc:"",
            //     accHolderName:"",
            //     accNumber:"",
            //     bank:"",
            //     accType:"",
            // },
        },
        validationSchema: validation,
        onSubmit: (v) => {
            submitKyc(v);
        },
    });

    const submitKyc = async (data) => {
        const fData = {
            investorId: user._id,
            aadharNumber: aadhaar,
            aadharInfo: JSON.stringify(aadhaarData),
            panCardNumber: data.panCardNumber,
            ifscCode: data.ifscCode,
            bankAccNumber: data.accNumber
        }
        setSubmitting(true);
        const res = await authServices.completeKyc(fData);
        setSubmitting(false);
        if (res && res.success) {
            // console.log(res);
            enqueueSnackbar("KYC details submitted", { variant: "success" });
            setAnimating(true)
            dispatch(saveUser(res.data));
            // setStep(3);
        }
    }

    const { errors, touched, handleSubmit, getFieldProps, values, setFieldValue, setFieldError, handleBlur, isSubmitting, setSubmitting } = formik;
    useEffect(() => {
        if (values.addressProof?.back) {
            // console.log(validation,"new");
            let temp = validation.shape({
                addressProofBack: Yup.mixed().nullable().required("Please upload this document.").test("FILE_SIZE", "Upload a file below 5MB.", value => value && value.size <= VALID_FILE_SIZE)
                    .test("FILE_FORMAT", "Uploaded file has unsupported format.", value => value && SUPPORTED_FORMATS.includes(value.type)),
            })
            setValidation(temp);
        }
        else {
            setValidation(kycSchema);
        }
    }, [values.addressProof, validation])
    console.log(errors)


    // const [frontSelectedImage, setFrontSelectedImage] = useState(null);
    // const [backSelectedImage, setBackSelectedImage] = useState(null);
    // const [panSelectedImage, setPanSelectedImage] = useState(null);

    // const handleImageChange = (file, type) => {
    //   if (file) {
    //     // Assuming that "file" is a File object
    //     const reader = new FileReader();

    //     reader.onload = (e) => {
    //       // Set the selected image to the uploaded image
    //       if(type === "frontImage"){
    //         setFrontSelectedImage(e.target.result);
    //       }else if(type=== "backImage"){
    //         setBackSelectedImage(e.target.result);
    //       }else if(type=== "panImage"){
    //         setPanSelectedImage(e.target.result);
    //       }
    //     };

    //     reader.readAsDataURL(file);
    //   } else {
    //     // If no file is selected, clear the selected image
    //     // setFrontSelectedImage(null);
    //   }
    // };

    const aadhaarRefs = [useRef(), useRef(), useRef()]; // Create refs for each box
    const [aadhaarValues, setAadhaarValues] = useState(["", "", ""]);
    const [aadhaarErrors, setAadhaarErrors] = useState([false, false, false]);

    const handleAadhaarChange = (index, value) => {
        if (/^\d*$/.test(value) && value.length <= 4) {
            setAadhaarValues((prevValues) => {
                const updatedValues = [...prevValues];
                updatedValues[index] = value;
                return updatedValues;
            });

            setAadhaarErrors((prevErrors) => {
                const updatedErrors = [...prevErrors];
                updatedErrors[index] = false;
                return updatedErrors;
            });

            if (value.length === 4 && index < 2) {
                aadhaarRefs[index + 1].current.focus();
            }
        } else {
            setAadhaarErrors((prevErrors) => {
                const updatedErrors = [...prevErrors];
                updatedErrors[index] = true;
                return updatedErrors;
            });
        }
    };


    return (
        <Box mt={4}>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <Typography variant="h4">Enter Your Document Details</Typography>
                        <Button variant="outlined" style={{mt: 3}} onClick={() => setIsManual(true)}>Switch to Manual KYC</Button>
                    </Stack>
                    <Stack spacing={1.5} mt={3} alignItems={'center'}>
                        <Stack
                            direction={{ md: "column", xs: "column" }}
                            spacing={{ md: 1, xs: 1.5 }}
                        >

                            <Stack mt={3}>
                                <Typography variant="label">Aadhaar Card No. *</Typography>
                                <Stack direction="row" alignItems="center"  spacing={{sm:2,xs:1}} mt={1} className="adharNoWrapper">
                                    {[0, 1, 2].map((index) => (
                                        <Box key={index}>
                                            <FormField
                                                inputProps={{
                                                    value: aadhaarValues[index],
                                                    onChange: (e) => {
                                                        handleAadhaarChange(index, e.target.value);
                                                    },
                                                    onKeyDown: (e) => {
                                                        if (e.key === "Backspace" && index > 0 && !e.target.value) {
                                                            aadhaarRefs[index - 1].current.focus();
                                                        } else if (e.key >= "0" && e.key <= "9" && index < 2 && e.target.value.length === 4) {
                                                            aadhaarRefs[index + 1].current.focus();
                                                        }
                                                    },
                                                    inputRef: aadhaarRefs[index],
                                                    placeholder: "XXXX",
                                                    inputMode: "numeric", // Enforce numeric keyboard on mobile
                                                    error: aadhaarErrors[index] || (Boolean(touched[`aadhaar${index}`] && errors[`aadhaar${index}`])),
                                                    helperText: aadhaarErrors[index] ? "" : (touched[`aadhaar${index}`] && errors[`aadhaar${index}`]),     
                                                    sx: {
                                                        '& .MuiInputBase-root': { minWidth: 0},
                                                        maxWidth:{lg:"150px !important",md:"110px !important"},
                                                        '& .MuiInputBase-input': { textAlign: 'center'},
                                                        '& .MuiOutlinedInput-root': {padding:{xs:"12px 10px !important"}}
                                                    }
                                                }}

                                            />
                                        </Box>
                                    ))}

                                    <Button
                                        onClick={submitAadhaar}
                                        sx={{marginTop:"8px !important"}}
                                        variant="contained"
                                        disabled={isAdharVerified}
                                    >
                                        Submit
                                    </Button>
                                </Stack>
                            </Stack>


                            <Stack item md={5} gap={0}>
                                <Stack>
                                    {isShowOPT && !isAdharVerified &&
                                        <OTPForm otp={otp} setOtp={setOtp} setOtpForm={setOtpForm} verifyAadhaar={verifyAadhaar} />

                                    }
                                </Stack>
                                <Stack mt={{sm:3,xs:1}}>
                                    {isAdharVerified &&
                                        <Grid>
                                            <Typography variant="label">PAN Card No. *</Typography>
                                            <Stack  sx={{ml:0}}>
                                            <FormField
                                                inputProps={{
                                                    placeholder: "",
                                                    ...getFieldProps("panCardNumber"),
                                                    error: Boolean(
                                                        touched.panCardNumber && errors.panCardNumber
                                                    ),
                                                    helperText: touched.panCardNumber && errors.panCardNumber,
                                                    sx: {
                                                        '& .MuiInputBase-root': { minWidth: 0},
                                                         maxWidth:{lg:"290px !important",md:"231px !important"}
                                                        }                                            
                                                }}
                                            />
                                            </Stack>

                                            <Stack
                                                direction={{ md: "row", xs: "column" }}
                                                spacing={{ lg:2 ,md: 2, xs: 2 }}
                                                mt={{sm:4,xs:2}}
                                            >
                                                <FormField
                                                    label={"Account Number *"}
                                                    inputProps={{
                                                        ...getFieldProps("accNumber"),
                                                        onChange: (e) => {
                                                            // console.log(RegExp('^[0-9]*$').test(e.target.value));
                                                            if (RegExp("^[0-9]*$").test(e.target.value)) {
                                                                setFieldValue(
                                                                    "accNumber",
                                                                    e.target.value
                                                                );
                                                            }
                                                        },
                                                        error: Boolean(
                                                            touched.accNumber &&
                                                            errors.accNumber
                                                        ),
                                                        helperText:
                                                            touched.accNumber &&
                                                            errors.accNumber,
                                                    }}
                                                />

                                                <FormField
                                                    label={"IFSC Code *"}
                                                    inputProps={{
                                                        ...getFieldProps("ifscCode"),
                                                        onBlur: (e) => {
                                                            handleBlur(e);
                                                            getBankFromIFSC();
                                                        },
                                                        error: Boolean(
                                                            touched.ifscCode &&
                                                            errors.ifscCode
                                                        ),
                                                        helperText:
                                                            (touched.ifscCode &&
                                                                errors.ifscCode) ||
                                                            showBranch,
                                                    }}
                                                />

                                            </Stack>

                                        </Grid>
                                    }
                                </Stack>
                            </Stack>

                        </Stack>
                        {/* <Stack
                direction={{ md: "row", xs: "column" }}
                spacing={{ md: 3, xs: 1.5 }}
              >
                <FormAutoCompleted
                  label={"Address Proof Document *"}
                  options={addressProofOptions}
                  props={{
                    value: values.addressProof,
                    onChange: (e, value) => {
                      setFieldValue("addressProof", value);
                    },
                    options: addressProofOptions,
                    error: Boolean(touched.addressProof && errors.addressProof),
                    helperText: touched.addressProof && errors.addressProof,
                  }}
                ></FormAutoCompleted>
              </Stack> */}

                        <Stack
                            direction={{ md: "row", xs: "column" }}
                            spacing={{ md: 3, xs: 1.5 }}
                        >
                            <Grid item md={6}>
                                {/* <FormFileField
                    label={"Upload Front Side *"}
                    inputProps={{
                      error: Boolean(
                        touched.addressProofFront && errors.addressProofFront
                      ),
                      helperText:
                        touched.addressProofFront && errors.addressProofFront,
                      value: values.addressProofFront,
                      onChange: (file) => {
                        setFieldValue("addressProofFront", file);
                        handleImageChange(file, "frontImage");
                      },
                    }}
                  /> */}
                                {/* Display the selected image in a square container */}

                                {/* {frontSelectedImage && (
                    <div
                      style={{
                        width: "auto",
                        height: "130px",
                        border: "1px solid rgb(183 183 183)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "6px",
                        marginTop: "8px",
                      }}
                    >
                        <img
                          src={frontSelectedImage}
                          alt="Selected Image"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            objectFit: "cover",
                          }}
                        />
                    </div>
                  )} */}
                            </Grid>

                            <Grid item md={6}>
                                {/* {values.addressProof?.back &&
                    <FormFileField
                      label={"Upload Back Side *"}
                      inputProps={{
                        error: Boolean(
                          touched.addressProofBack && errors.addressProofBack
                        ),
                        helperText:
                          touched.addressProofBack && errors.addressProofBack,
                        value: values.addressProofBack,
                        onChange: (file) =>{
                            setFieldValue("addressProofBack", file);
                            handleImageChange(file, "backImage");
                        },
                      }}
                    />} */}
                                {/* 
{backSelectedImage &&  values.addressProof?.back && 
                    <div
                      style={{
                        width: "auto",
                        height: "130px",
                        border: "1px solid rgb(183 183 183)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "6px",
                        marginTop: "8px",
                      }}
                    >
                        <img
                          src={backSelectedImage}
                          alt="Selected Image"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            objectFit: "cover",
                          }}
                        />
                    </div>
                  } */}

                            </Grid>
                        </Stack>
                    </Stack>

                    <Stack alignItems={'center'}>
                        {isAdharVerified &&

                            <Grid>
                                {/* <Typography mt={5} variant="h4">
              Enter Bank Account Information
            </Typography> */}

                                <LoadingButton
                                    loading={isSubmitting}
                                    type="submit"
                                    variant="contained"
                                    sx={{ mt:{sm:3,xs:2}, width: { md: "auto", xs: "100%" } }}
                                    loadingIndicator={
                                        <CircularProgress size={30} sx={{ color: 'red' }} /> 
                                      }
                                >
                                    Submit
                                </LoadingButton>
                            </Grid>
                        }
                    </Stack>

                </Form>
            </FormikProvider>
        </Box>
    );
}


const ManualKYCForm = ({setIsManual, setStep,user,enqueueSnackbar,dispatch,handleClose,setAnimating}) =>{

    const [showBranch,setShowBranch] = useState("");

    const [validation, setValidation] = React.useState(manualKycSchema);

    const addressProofOptions=[
        {
            title:"Aadhar Card",
            label:"Aadhar Card",
            value:"Aadhar Card",
            back:true
        },
        {
            title:"Voter Card",
            label:"Voter Card",
            value:"Voter Card",
            back:false
        },
        {
            title:"Passport",
            label:"Passport",
            value:"Passport",
            back:true
        },
        {
            title:"Driving license",
            label:"Driving license",
            value:"Driving license",
            back:false
        },
    ];

    const accTypeOptions =[
        {
            title:"Current account",
            value:"Current account",
        },
        {
            title:"Saving account",
            value:"Saving account",
        },
    ]

    const getBankFromIFSC =  async() =>{
        console.log(values?.bankInformation?.ifsc);
        if(values?.bankInformation?.ifsc){
            const res = await bankServices.getBankFromIFSCCode(values?.bankInformation?.ifsc);
            if(res){
                console.log(res)
                setShowBranch(`${res.BANK}, ${res.BRANCH}`);
                setFieldValue("bankInformation.bank",res.BANK)
                setFieldValue("bankInformation.branch",res.BRANCH)
            }
            else{
                setShowBranch("Not Found");
                setFieldError("bankInformation.ifsc","Not Found")
            }
        }
    }
    const formik = useFormik({
        initialValues: {
            panCardNumber:"",
            addressProof:"",
            panCard:null,
            bankCheque:null,
            addressProofFront:null,
            addressProofBack:null,
            bankInformation:{
                ifsc:"",
                accHolderName:"",
                accNumber:"",
                bank:"",
                accType:"",
            },
            userAddress:{
                flatNo:"",
                area:"",
                landmark:"",
                town:"",
                state:"",
                pincode:""
            }
        },
        validationSchema: validation,
        onSubmit: (v) => {
            console.log(v);
            submitKyc(v);
        },
    });

    const submitKyc = async (data)=>{
        const formData = new FormData();
        Object.keys((data)).map((key)=>formData.append(key,data[key]));
        formData.delete("addressProof");
        formData.append("addressProof",data.addressProof.value);
        formData.delete("bankInformation");
        formData.append("bankInformation",JSON.stringify(data.bankInformation));
        formData.append("userAddress",data.userAddress);
        formData.append("investorId",user._id);
        setSubmitting(true);
        const res = await authServices.completeManualKyc(formData);
        setSubmitting(false);
        if(res && res.success){
            // console.log(res);
            enqueueSnackbar("KYC details submitted",{variant:"success"});
            setAnimating(true)
            dispatch(saveUser(res.data));
            setTimeout(()=>{
                handleClose();
            },1500)
        }
    }
    
    const { errors, touched, handleSubmit, getFieldProps,values,setFieldValue,setFieldError,handleBlur,isSubmitting,setSubmitting } = formik;
    useEffect(()=>{
        if(values.addressProof?.back){
            // console.log(validation,"new");
            let temp = validation.shape({addressProofBack:Yup.mixed().nullable().required("Please upload this document.").test("FILE_SIZE", "Upload a file below 5MB.", value => value && value.size <= VALID_FILE_SIZE)
            .test("FILE_FORMAT", "Uploaded file has unsupported format.", value => value && SUPPORTED_FORMATS.includes(value.type)),})
            setValidation(temp);
        }
        else{
            setValidation(manualKycSchema);
        }
    },[values.addressProof,validation])
    console.log(errors)


    const [frontSelectedImage, setFrontSelectedImage] = useState(null);
    const [backSelectedImage, setBackSelectedImage] = useState(null);
    const [panSelectedImage, setPanSelectedImage] = useState(null);

    const handleImageChange = (file, type) => {
      if (file) {
        // Assuming that "file" is a File object
        const reader = new FileReader();
  
        reader.onload = (e) => {
          // Set the selected image to the uploaded image
          if(type === "frontImage"){
            setFrontSelectedImage(e.target.result);
          }else if(type=== "backImage"){
            setBackSelectedImage(e.target.result);
          }else if(type=== "panImage"){
            setPanSelectedImage(e.target.result);
          }
        };
  
        reader.readAsDataURL(file);
      } else {
        // If no file is selected, clear the selected image
        // setFrontSelectedImage(null);
      }
    };
  

    return (
      <Box mt={4}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography variant="h4">Upload Your Documents</Typography>
                <Button variant="outlined" style={{mt: 3}} onClick={() => setIsManual(false)}>Switch Back to Instant KYC</Button>
            </Stack>
            <Stack spacing={1.5} mt={3}>
              <Stack
                direction={{ md: "row", xs: "column" }}
                spacing={{ md: 3, xs: 1.5 }}
              >


                <Grid item md={6}>
                <FormFileField
                  label={"Upload PAN Card *"}
                  inputProps={{
                    error: Boolean(touched.panCard && errors.panCard),
                    helperText: touched.panCard && errors.panCard,
                    value: values.panCard,
                    onChange: (file) => {
                        setFieldValue("panCard", file)
                        handleImageChange(file, "panImage");
                },
                  }}
                />

                  {/* Display the selected image in a square container */}

                  {panSelectedImage && (
                    <div
                      style={{
                        width: "auto",
                        height: "130px",
                        border: "1px solid rgb(183 183 183)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "6px",
                        marginTop: "8px",
                      }}
                    >
                        <img
                          src={panSelectedImage}
                          alt="Selected Image"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            objectFit: "cover",
                          }}
                        />
                    </div>
                  )}
                </Grid>


                <FormField
                  label={"Enter PAN Card No. *"}
                  inputProps={{
                    placeholder: "",
                    ...getFieldProps("panCardNumber"),
                    error: Boolean(
                      touched.panCardNumber && errors.panCardNumber
                    ),
                    helperText: touched.panCardNumber && errors.panCardNumber,
                  }}
                />
              </Stack>
              <Stack
                direction={{ md: "row", xs: "column" }}
                spacing={{ md: 3, xs: 1.5 }}
              >
                <FormAutoCompleted
                  label={"Address Proof Document *"}
                  options={addressProofOptions}
                  props={{
                    value: values.addressProof,
                    onChange: (e, value) => {
                      setFieldValue("addressProof", value);
                    },
                    options: addressProofOptions,
                    error: Boolean(touched.addressProof && errors.addressProof),
                    helperText: touched.addressProof && errors.addressProof,
                  }}
                ></FormAutoCompleted>
              </Stack>

              <Stack
                direction={{ md: "row", xs: "column" }}
                spacing={{ md: 3, xs: 1.5 }}
              >
                <Grid item md={6}>
                  <FormFileField
                    label={"Upload Front Side *"}
                    inputProps={{
                      error: Boolean(
                        touched.addressProofFront && errors.addressProofFront
                      ),
                      helperText:
                        touched.addressProofFront && errors.addressProofFront,
                      value: values.addressProofFront,
                      onChange: (file) => {
                        setFieldValue("addressProofFront", file);
                        handleImageChange(file, "frontImage");
                      },
                    }}
                  />
                  {/* Display the selected image in a square container */}

                  {frontSelectedImage && (
                    <div
                      style={{
                        width: "auto",
                        height: "130px",
                        border: "1px solid rgb(183 183 183)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "6px",
                        marginTop: "8px",
                      }}
                    >
                        <img
                          src={frontSelectedImage}
                          alt="Selected Image"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            objectFit: "cover",
                          }}
                        />
                    </div>
                  )}
                </Grid>

                <Grid item md={6}>
                  {values.addressProof?.back &&
                    <FormFileField
                      label={"Upload Back Side *"}
                      inputProps={{
                        error: Boolean(
                          touched.addressProofBack && errors.addressProofBack
                        ),
                        helperText:
                          touched.addressProofBack && errors.addressProofBack,
                        value: values.addressProofBack,
                        onChange: (file) =>{
                            setFieldValue("addressProofBack", file);
                            handleImageChange(file, "backImage");
                        },
                      }}
                    />}

{backSelectedImage &&  values.addressProof?.back && 
                    <div
                      style={{
                        width: "auto",
                        height: "130px",
                        border: "1px solid rgb(183 183 183)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "6px",
                        marginTop: "8px",
                      }}
                    >
                        <img
                          src={backSelectedImage}
                          alt="Selected Image"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            objectFit: "cover",
                          }}
                        />
                    </div>
                  }

                </Grid>
              </Stack>
            </Stack>
            <Typography mt={5} variant="h4">
              Enter Bank Account Information
            </Typography>
            <Stack spacing={1.5} mt={3}>
              <FormField
                label={"Account Holder Name *"}
                inputProps={{
                  ...getFieldProps("bankInformation.accHolderName"),
                  error: Boolean(
                    touched.bankInformation?.accHolderName &&
                      errors.bankInformation?.accHolderName
                  ),
                  helperText:
                    touched.bankInformation?.accHolderName &&
                    errors.bankInformation?.accHolderName,
                }}
              />
              <Stack
                direction={{ md: "row", xs: "column" }}
                spacing={{ md: 3, xs: 1.5 }}
              >
                <FormField
                  label={"IFSC CODE *"}
                  inputProps={{
                    ...getFieldProps("bankInformation.ifsc"),
                    onBlur: (e) => {
                      handleBlur(e);
                      getBankFromIFSC();
                    },
                    error: Boolean(
                      touched.bankInformation?.ifsc &&
                        errors.bankInformation?.ifsc
                    ),
                    helperText:
                      (touched.bankInformation?.ifsc &&
                        errors.bankInformation?.ifsc) ||
                      showBranch,
                  }}
                />
                <FormField
                  label={"Account Number *"}
                  inputProps={{
                    ...getFieldProps("bankInformation.accNumber"),
                    onChange: (e) => {
                      // console.log(RegExp('^[0-9]*$').test(e.target.value));
                      if (RegExp("^[0-9]*$").test(e.target.value)) {
                        setFieldValue(
                          "bankInformation.accNumber",
                          e.target.value
                        );
                      }
                    },
                    error: Boolean(
                      touched.bankInformation?.accNumber &&
                        errors.bankInformation?.accNumber
                    ),
                    helperText:
                      touched.bankInformation?.accNumber &&
                      errors.bankInformation?.accNumber,
                  }}
                />
              </Stack>
              <Stack
                direction={{ md: "row", xs: "column" }}
                spacing={{ md: 3, xs: 1.5 }}
              >
                {/* <FormSelect 
                                label={"Bank *"}
                                options={[]} 
                            /> */}
                <FormSelect
                  label={"Account Type *"}
                  options={accTypeOptions}
                  props={{
                    ...getFieldProps("bankInformation.accType"),
                    error: Boolean(
                      touched.bankInformation?.accType &&
                        errors.bankInformation?.accType
                    ),
                    helperText:
                      touched.bankInformation?.accType &&
                      errors.bankInformation?.accType,
                  }}
                />
                <FormFileField
                  label={"Cancelled Check Image*"}
                  inputProps={{
                    error: Boolean(touched.bankCheque && errors.bankCheque),
                    helperText: touched.bankCheque && errors.bankCheque,
                    value: values.bankCheque,
                    onChange: (file) => setFieldValue("bankCheque", file),
                  }}
                />
              </Stack>
            </Stack>

            <Typography mt={5} variant="h4">
              Enter Address Information
            </Typography>
            <Stack
                direction={{ md: "row", xs: "column" }}
                spacing={{ md: 3, xs: 1.5 }}
              >
              <FormField
                label={"Flat No,House No *"}
                inputProps={{
                  ...getFieldProps("userAddress.flatNo"),
                  error: Boolean(
                    touched.userAddress?.flatNo &&
                      errors.userAddress?.flatNo
                  ),
                  helperText:
                    touched.userAddress?.flatNo &&
                    errors.userAddress?.flatNo,
                }}
              />
                 <FormField
                label={"Area,Street *"}
                inputProps={{
                  ...getFieldProps("userAddress.area"),
                  error: Boolean(
                    touched.userAddress?.area &&
                      errors.userAddress?.area
                  ),
                  helperText:
                    touched.userAddress?.area &&
                    errors.userAddress?.area,
                }}
              />
            </Stack>
            <Stack
                direction={{ md: "row", xs: "column" }}
                spacing={{ md: 3, xs: 1.5 }}
              >
              <FormField
                label={"Landmark"}
                inputProps={{
                  ...getFieldProps("userAddress.landmark"),
                  error: Boolean(
                    touched.userAddress?.landmark &&
                      errors.userAddress?.landmark
                  ),
                  helperText:
                    touched.userAddress?.landmark &&
                    errors.userAddress?.landmark,
                }}
              />
                 <FormField
                label={"Town/City *"}
                inputProps={{
                  ...getFieldProps("userAddress.town"),
                  error: Boolean(
                    touched.userAddress?.town &&
                      errors.userAddress?.town
                  ),
                  helperText:
                    touched.userAddress?.town &&
                    errors.userAddress?.town,
                }}
              />
            </Stack>
            <Stack
                direction={{ md: "row", xs: "column" }}
                spacing={{ md: 3, xs: 1.5 }}
              >
              <FormField
                label={"State"}
                inputProps={{
                  ...getFieldProps("userAddress.state"),
                  error: Boolean(
                    touched.userAddress?.state &&
                      errors.userAddress?.state
                  ),
                  helperText:
                    touched.userAddress?.state &&
                    errors.userAddress?.state,
                }}
              />
                 <FormField
                label={"Pincode *"}
                inputProps={{
                  ...getFieldProps("userAddress.pincode"),
                  error: Boolean(
                    touched.userAddress?.pincode &&
                      errors.userAddress?.pincode
                  ),
                  helperText:
                    touched.userAddress?.pincode &&
                    errors.userAddress?.pincode,
                }}
              />
            </Stack>

            <LoadingButton
              loading={isSubmitting}
              type="submit"
              variant="contained"
              sx={{ mt: 5, width: { md: "auto", xs: "100%" } }}
            >
              Submit
            </LoadingButton>
          </Form>
        </FormikProvider>
      </Box>
    );
}


const BlockchainAcc = ({ user, enqueueSnackbar, dispatch, customHandleClose, setAnimating, sendMail, setSendMail, mnemonic, setmnemonic, setBlockchainAccountDownload, setBlockchainAccountCreated, setEncryptedMnemonic, dltName, setDltName }) => {

    const [loading, setLoading] = useState(false);
    const [keyDownloadButtonTxt, setKeyDownloadButtonTxt] = useState('Download Encrypted Key')
    const [confirmModal,setConfirmModal] = useState(false)

    const createBlockChainAcc = async () => {
        if(dltName==="") {
            setConfirmModal(true)
            return
        }
        await saveDlt()
    }

    const copyText = (text) => {
        navigator.clipboard.writeText(text);
        enqueueSnackbar("Copied to clipboard.", { variant: "success" })
    }

    const downloadTxtFile = async () => {
        // eslint-disable-next-line
        const element = document.createElement("a");
        // eslint-disable-next-line
        const res = await authServices.encryptCredentials({ mnemonic });
        if (res && res.success) {
            setEncryptedMnemonic(res.data);
            const file = new Blob([user.walletInfo.publicAddress + "|" + res.data], { type: 'text/plain' });
            element.href = URL.createObjectURL(file);
            element.download = `DIGITAL_ASSET_ACCOUNT_${user.walletInfo.publicAddress}.txt`;
            // document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
            setBlockchainAccountDownload(true);
        }
        else {
            enqueueSnackbar("Something Went Wrong, Please Try Again!", { variant: "error" });
        }
        setKeyDownloadButtonTxt('Encrypted Key Downloaded')
    }

    const saveDlt = async () => {
        let data = {
            "investorId": user._id,
            "dltName": dltName
        }
        setLoading(true);
        let res = await authServices.createBlockChainAcc(data);
        setLoading(false);
        if (res && res.success) {
            console.log(res);
            setAnimating(true);
            setmnemonic(res?.mnemonic);
            dispatch(saveUser(res.data));
            setBlockchainAccountCreated(true);
        }
        else {
            enqueueSnackbar("Something went wrong!!!", { variant: "error" });
        }
    }


    return (
        <Box mt={4}>
            <AreYouSureModal message={"Do you want to save without wallet name?"} setOpen={setConfirmModal} open={confirmModal} handleYes={()=>{saveDlt()}} />
            {
                (user.status === "APPROVED") ?
                    <Stack alignItems={"center"} mt={{ md: 8 }} spacing={{ md: 6, xs: 3 }}>
                        <Box
                            sx={{
                                width: { md: 112, xs: 88 }, height: { md: 112, xs: 88 },
                                bgcolor: "success.light",
                                borderRadius: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                mt: { md: 0, xs: 3 }
                            }}
                        >
                            <CustomIcon name="check" sx={{ bgcolor: "success.main", width: { md: 50, xs: 32 }, height: { md: 50, xs: 32 }, }} />
                        </Box>
                        <Stack spacing={3} width={"100%"} alignItems={{ md: "center" }}>
                            <Stack alignItems={"center"} spacing={1} sx={{ maxWidth: 408 }} textAlign={"center"}>
                                <Typography variant="h4">Digital Assets Account Created</Typography>
                                <Typography variant="subtitle1" sx={{ color: "#000", fontWeight: 400 }}>
                                    Your digital assets account is successfully created.
                                    All the tokens that you subscribed to will be credited to this account.
                                    <i><b> PLEASE SAVE AND KEEP SECURE THE BELOW PRIVATE KEY (PASSWORD) OF YOUR BLOCKCHAIN ACCOUNT.</b></i></Typography>
                            </Stack>
                            <Box px={{ md: 2 }}>
                                <Box
                                    sx={{
                                        p: 3,
                                        borderRadius: "6px",
                                        border: "1px solid #E1E1E1",
                                    }}
                                >
                                    <Stack spacing={1.5}>
                                        <Stack spacing={0.5}>
                                            <Typography variant="subtitle1">Account ID  (Public Key)</Typography>
                                            <Stack direction={"row"} px={3} py={1.5} justifyContent={"space-between"} sx={{ border: "1px solid #E1E1E1", borderRadius: 1 }}>
                                                <Typography variant="body2" sx={{ textOverflow: "ellipsis", width: { md: 256, xs: "100%" }, whiteSpace: "nowrap", overflow: "hidden" }}>{user.walletInfo.publicAddress}</Typography>
                                                <Box onClick={() => copyText(user.walletInfo.publicAddress)} sx={{ cursor: "pointer", }}>
                                                    <CustomIcon name="copy" />
                                                </Box>
                                            </Stack>
                                        </Stack>
                                        <Stack spacing={0.5}>
                                            <Typography variant="subtitle1">Password (Private Key)</Typography>
                                            <Stack spacing={0.5} direction={"row"} px={3} py={1.5} justifyContent={"space-between"} sx={{ border: "1px solid #E1E1E1", borderRadius: 1 }}>
                                                <Typography variant="body2" sx={{ textOverflow: "ellipsis", width: { md: 256, xs: "100%" }, whiteSpace: "nowrap", overflow: "hidden" }}>{mnemonic}</Typography>
                                                <Box onClick={() => copyText(mnemonic)} sx={{ cursor: "pointer", }}>
                                                    <CustomIcon name="copy" />
                                                </Box>
                                            </Stack>
                                        </Stack>
                                        <Button onClick={downloadTxtFile} variant="contained" sx={{ mb: 1 }}>{keyDownloadButtonTxt}</Button>
                                    </Stack>
                                </Box>
                            </Box>

                            <Box sx={{
                                maxWidth: { md: 408, xs: "100%" },
                                bgcolor: "warning.light",
                                px: "10px",
                                py: 1,
                                borderRadius: 0.5
                            }}>
                                <Stack direction={'row'} rowGap={2}>
                                    <Checkbox
                                        checked={sendMail}
                                        onClick={() => setSendMail(!sendMail)}
                                        color="primary"

                                    />
                                    <Typography variant="subtitle2" sx={{ color: "#000", fontWeight: 400 }}>Send key detail file to my email.Keep this file secure as it can be used to access DLT account.</Typography>
                                </Stack>
                            </Box>
                            <Stack px={{ md: 6 }}>
                                <Button onClick={customHandleClose} variant="outlined" sx={{ mb: 1 }}>Complete Account Creation</Button>
                            </Stack>
                            <Box
                                sx={{
                                    maxWidth: 408,
                                    bgcolor: "warning.light",
                                    px: "10px",
                                    py: 1,
                                    borderRadius: 0.5,
                                }}
                            >
                                <Stack direction={"row"} spacing={0.5}>
                                    <CustomIcon name="warning" sx={{ bgcolor: "warning.main", width: 80, height: 20, }} />
                                    <Typography variant="subtitle2" sx={{ color: "#000", fontWeight: 400 }}>
                                        Kindly note the private key is your own secure access key for your account.
                                        RealX does not store your private key and therefore will not have access to your account or ability to recover this key.
                                    </Typography>
                                </Stack>
                            </Box>
                        </Stack>
                    </Stack>
                    :
                    <Stack alignItems={"center"} mt={{ md: 15 }} spacing={{ md: 6, xs: 3 }} >
                        <Box
                            sx={{
                                width: { md: 132, xs: 88 }, height: { md: 132, xs: 88 },
                                bgcolor: "primary.light",
                                borderRadius: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                mt: { md: 0, xs: 3 }
                            }}
                        >
                            <CustomIcon name="blockchain" sx={{ bgcolor: "primary.main", width: { md: 70, xs: 32 }, height: { md: 70, xs: 32 }, }} />
                        </Box>
                        <Stack spacing={3}>
                            <Stack alignItems={"center"} spacing={1} sx={{ maxWidth: 408 }} textAlign={"center"}>
                                <Typography variant="h4">Create Your Digital Assets Account</Typography>
                                <Typography variant="subtitle1" sx={{ color: "#000", fontWeight: 400 }}>You'll need to create a new digital assets account to receive the tokens.</Typography>
                            </Stack>
                            <Stack px={{ md: 6 }}>
                                <FormField label={"Enter Nick Name"} inputProps={{
                                    placeholder: "Primary Account",
                                    type: "text",
                                    value: dltName,
                                    onChange: (e) => {
                                        setDltName(e.target.value)
                                    },
                                }} />
                            </Stack>
                            <Stack px={{ md: 6 }}>
                                <LoadingButton loading={loading} onClick={() => createBlockChainAcc()} 
                                variant="contained" sx={{ mb: 1 }}
                                loadingIndicator={
                                    <CircularProgress size={30} sx={{ color: 'red' }} /> 
                                  }
                                >
                                    Create Account
                                </LoadingButton>
                            </Stack>
                            <Box
                                sx={{
                                    maxWidth: 408,
                                    bgcolor: "warning.light",
                                    px: "10px",
                                    py: 1,
                                    borderRadius: 0.5,
                                }}
                            >
                                <Stack direction={"row"} spacing={0.5}>
                                    <CustomIcon name="warning" sx={{ bgcolor: "warning.main", width: 80, height: 20, }} />
                                    <Typography variant="subtitle1" sx={{ color: "#000", fontWeight: 400 }}>
                                        Once your account is created, you'll see a private key of your digital assets account.
                                        <span style={{ fontWeight: 600 }}> Please save this key</span> as this is the password to access your digital assets account.
                                        This key is only known to you and RealX does not store or has any knowledge of it.
                                    </Typography>
                                </Stack>
                            </Box>
                        </Stack>
                    </Stack>
            }
        </Box>
    )
}

const OTPForm = ({ otp, setOtp, setOtpForm, verifyAadhaar }) => {

    return (
        <Stack alignItems={'center'}>
            <Stack direction={"row"} mt={1} justifyContent={"space-between"}>
                <Typography variant="body2" sx={{ fontSize: 12 }}>OTP Sent To Your Registered Mobile Number</Typography>
            </Stack>
            <Stack spacing={1.5} mt={3}>
                <FormField label={"Enter OTP *"} inputProps={{
                    placeholder: "_ _ _ _ _ _",
                    type: "number",
                    value: otp,
                    onChange: (e) => {
                        console.log(RegExp('^[0-9]*$').test(e.target.value));
                        if (RegExp('^[0-9]*$').test(e.target.value) && e.target.value?.length < 7) {
                            setOtp(e.target.value)
                        }

                    },
                    sx: {'& .MuiInputBase-root': { minWidth: 0, padding: "11px auto !important",textAlign: 'center'},maxWidth: "250px !important",'& .MuiInputBase-input': { textAlign: 'center'}}
                }} />
            </Stack>
            {/* <Stack direction={"row"} mt={2} spacing={1}>
                <Typography variant="body2" >Haven't received OTP?</Typography>
                <Typography variant="body2" sx={{ color: "primary.main", cursor: "pointer" }}>Resend OTP</Typography>
            </Stack> */}
            <Stack alignItems={"center"} sx={{width:'250px'}}>
            <Button onClick={verifyAadhaar} disabled={otp.length !== 6} variant="contained" sx={{ mt: 3 }}  >Verify OTP</Button>
            </Stack>
        </Stack>
    )
}