import { Box,Card, CardContent, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Tabs from "../../components/tabs/tabs";
import PropertyCard from './../../components/propertyCard';
import ProjectCard from "../../components/projectCard";
import DeveloperCard from "../../components/developerCard";
import CustomIcon from "../../components/customIcon";
import MobileBottomTab from "../../components/tabs/mobileBottomTab";
import listingService from './../../services/listingServices';
import { checkLogin } from "../../utils/functions";
import store from "../../redux/store";

export default function MarketPlace(){
    
    const tabs = [
        {
            name:"Properties",
            tab:<PropertiesTabPanel />
        },
        {   
            name:"Projects",
            tab:<ProjectTabPanel />
        },
        // {
        //     name:"Developers",
        //     tab:<DeveloperTabPanel />
        // },
    ];

    const [activeTab,setActiveTab] = useState(0);


    const handleTabClick = (index) =>{
        setActiveTab(index);
    }

    return(
        <Card sx={{mt:checkLogin()?{md:3,xs:3}:{md:15,xs:12},mx:checkLogin()?0:{xl:"100px",lg:"32px",md:"24px",xs:"16px"},mb:{xl:2,lg:3,md:2,xs:"77px"}}}>
            <CardContent>
                <Stack spacing={"5px"}>
                    <Stack direction={"row"} spacing={{md:3,xs:1.5}} alignItems={"center"} >
                        <Box sx={{
                                width:{md:48,xs:32},height:{md:48,xs:32},
                                bgcolor:"primary.light",
                                borderRadius:"100%",
                                display:"flex",
                                justifyContent:"center",
                                alignItems:"center"
                            }}>
                            {/* <CircleIcon sx={{width:24,height:24}} /> */}
                            <CustomIcon name="media-live" sx={{bgcolor:"primary.main",width:{md:24,xs:16},height:{md:24,xs:16}}} />
                        </Box>
                        <Typography variant="mainCardTitle" sx={{flex:1}}>Properties Listings</Typography>
                    </Stack>
                </Stack>
                <Stack direction={"row"} justifyContent={"space-between"}
                    sx={{
                        mt:{md:2,xs:0},
                        py:1,
                        pb:"10px",
                        pl:{md:0,xs:0},
                    }}
                    
                >
                    <Tabs activeTab={activeTab} tabs={tabs} onClick={handleTabClick} />
                    {/* <Stack direction={"row"} spacing={2}>
                        <TextField variant="outlined" placeholder="Search" InputLabelProps={{ shrink: true }} />
                        <Button variant="outlined">Advanced Filters</Button>
                    </Stack> */}
                </Stack>
                <Divider sx={{
                    width:"calc(100% + 80px)",
                    mx:-5
                }} />
                <Grid container columnSpacing={2} rowSpacing={3} mt={{md:3,xs:1}} sx={{backgroundColor: "#f7f7f7", paddingBottom: 5}}>
                    {
                        tabs[activeTab]?.tab
                    }
                </Grid>
            </CardContent>
            {
                checkLogin()?
                <MobileBottomTab />
                :""
            }
        </Card>
    )
}

const PropertiesTabPanel = () =>{
    const [listings,setListings] = useState([]);
    const [url,setUrl] = useState("");
    
    useEffect(()=>{
        getListings();
    },[])

    const getListings = async()=>{
        const user = store.getState().user.data;
        let res = await listingService.getApprovedListing(user._id);
        // console.log(res);
        if(res && res.success){
            
            if(user.membershiptype === "SELECT"){
                getSelectListings(res.data, user._id);
            }else{
                setListings(res.data);
                setUrl(res.url)
            }

        }
    }


    const getSelectListings = async(list, userId)=>{
        const res = await listingService.getApprovedSelectListing(userId);
        if(res && res.success){
            let propertyList = [
                ...res.data,...list
            ]
            setListings(propertyList);
            setUrl(res.url)
        }
    }


    return (
        listings.map((e,i)=>{
            // console.log(e,"prop");
            return(
                <Grid item lg={4} md={6} sm={6} xs={12}>
                    <PropertyCard data={e} url={url} />
                </Grid>
            )    
        }))
    
} 

const ProjectTabPanel = () =>{
    const [projects,setProjects] = useState([]);
    const [url,setUrl] = useState("");
    
    useEffect(()=>{
        getProjects();
    },[])

    const getProjects = async()=>{
        let res = await listingService.getApprovedProject();
        console.log(res);
        if(res && res.success){
            setProjects(res.data);
            setUrl(res.url)
        }
    }
    return (
        projects.map((e,i)=>{
            return(
                <Grid item md={"auto"} xs={12}>
                    <ProjectCard data={e} url={url}/>
                </Grid>
            )    
        }))
    
} 

// eslint-disable-next-line
const DeveloperTabPanel = () =>{
    return (
        [...Array(16)].map((e,i)=>{
            return(
                <Grid item md={"auto"} xs={12}>
                    <DeveloperCard />
                </Grid>
            )    
        }))
    
} 