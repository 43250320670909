import { Box, Button, Card, CardContent, Dialog, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import transactionServices from "../../services/transactionServices";
import { DividerComponent } from "../miniComponents";
import CloseIcon from '@mui/icons-material/Close';

export default function CompletePaymentModal({ open, handleClose = () => { }, data, isFullPayment, paymentDetails, getTransaction = () => { } }) {

    const { enqueueSnackbar } = useSnackbar();

    const [submitting, setSubmitting] = useState(false);

    const handleSubmit = async () => {
        if (data) {
            setSubmitting(true);
            const req = await transactionServices.createTransaction({
                ...data,
                isFullPayment,
                paymentMethod: "DBT"
            });
            if (req && req.success) {
                enqueueSnackbar("Success", { variant: "success" });
                getTransaction();
                handleClose();
            }
            else {
                if (req.data) enqueueSnackbar(req.data.message, { variant: "error" });
                else enqueueSnackbar("Something went wrong!!", { variant: "error" });
            }
            setSubmitting(false);
        }
    }

    return (
        <Dialog
            scroll={"body"}
            PaperProps={{
                sx: {
                    overflow: "auto",
                    maxWidth: { md: "700px !important", xs: "100% !important" },
                    margin: { xs: 0 },
                    borderRadius: { xs: 0, md: 1 },
                    height: { xs: "100%", md: "auto" },
                    width: { xs: "100%" }
                }
            }}
            open={open}
            onClose={handleClose}
        >
            <Card>
                <CardContent sx={{ paddingTop: { md: "20px" } }}>
                    <Box
                        sx={{
                            width: "100%",
                            top: 0,
                            left: 0,
                            bgcolor: "white",
                            position: { xs: "fixed", md: "relative" },
                            boxShadow: { xs: "0px -3px 10px #00000066", md: "none" },
                            padding: { xs: "16px 24px", md: 0 },
                            zIndex: 4
                        }}
                    >
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Typography variant="body3" sx={{ fontWeight: 500 }}>Property Details</Typography>
                            <CloseIcon onClick={handleClose} sx={{ width: 24, height: 24, color: "text.disabled", cursor: "pointer" }} />
                        </Stack>
                    </Box>
                    <Stack mt={2} spacing={4}>
                        <Box>
                            <DividerComponent />
                        </Box>
                        {
                            paymentDetails
                        }
                        <Box sx={{ height: { xs: 40, md: "auto" } }}>
                        </Box>
                        <Box sx={{ display: { xs: "none", md: "block" } }}>
                            <DividerComponent />
                        </Box>
                        <Box sx={{
                            width: "100%",
                            bottom: 0,
                            left: 0,
                            bgcolor: "white",
                            borderRadius: "15px 15px 0px 0px",
                            position: { xs: "fixed", md: "relative" },
                            boxShadow: { xs: "0px 3px 10px #00000066", md: "none" },
                            padding: { xs: "16px 24px", md: 0 },
                        }}>
                            <Box sx={{ px: { xs: 0, md: 8 } }}>
                                <Button onClick={handleSubmit} disabled={submitting} fullWidth variant="contained" color="secondary">Confirm</Button>
                            </Box>
                        </Box>
                    </Stack>
                </CardContent>
            </Card>
        </Dialog>
    )
}
