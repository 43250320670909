
import { Box, Button, Typography } from "@mui/material";
import Lottie from 'lottie-react';
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react'
import warning from "../../../animations/warning.json"
const Notification = ({ message, redirectPath }) => {
    const navigate = useNavigate();

    return (
        <Box sx={{ 
            width: "500px", 
            padding: "30px", 
            display: "flex", 
            flexDirection: "column", 
            justifyContent: "center", 
            alignItems: "center", 
            textAlign: "center",
            backgroundColor:"#f1dddd ",
            borderRadius:"10px"
            
          }}>
          
            <Box sx={{
              width: 150,
              height: 150, 
              marginTop: "-35px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
              <Lottie animationData={warning} loop={false} />
            </Box>
          
            <Box>
              <Typography>{message}</Typography>
            </Box>
          
            <Box sx={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
              <Button
                onClick={() => {
                  navigate(redirectPath);
                }}
                sx={{
                  backgroundColor: "#ee3131",
                  color: "white",
                  ":hover": { backgroundColor: "#de5050" }
                }}
              >
                Ok
              </Button>
            </Box>
          </Box>
    );
};

export default Notification;
