import { Box, Button, Card, CardContent, Dialog, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import transactionServices from "../../services/transactionServices";
import { convertToIndianFormat } from "../../utils/functions";
import { DividerComponent } from "../miniComponents";
import CloseIcon  from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";

export default function RejectTransaction({open,handleClose=()=>{},data,amount,pendingAmt,amtTobePaid}){

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const handleSubmit = async() =>{
        if(data){
            console.log(data);
            const req = await transactionServices.updateTransaction({
                ...data,
                status:"REJECTED",
                rejectReason:"Transaction is cancelled by investor"
            });
            console.log(req);
            if(req && req.success){
                enqueueSnackbar("Success",{variant:"success"});
                handleClose();
                navigate("/dashboard")
            }
            else{
                if(req.data){
                    enqueueSnackbar(req.data.message,{variant:"error"});
                }
                else{
                    enqueueSnackbar("Something went wrong!!",{variant:"error"});
                }
            }
        }
    }

    return(
        <Dialog
        scroll={"body"}
        PaperProps={{sx:{overflow:"auto",maxWidth:470}}}
        open={open}
        onClose={handleClose}
            >
                <Card>
                    <CardContent sx={{paddingTop:{md:"20px"}}}>
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Typography variant="body3" sx={{fontWeight:500}}>Cancel Transaction</Typography>
                            <CloseIcon onClick={handleClose} sx={{width:24,height:24,color:"text.disabled",cursor:"pointer"}} />
                        </Stack>
                        <Stack mt={2} spacing={3}>
                            <Box>
                                <DividerComponent />
                            </Box>
                            <Stack spacing={"10px"}>
                                <Typography variant="body1" sx={{fontWeight:500}}>Are you sure you want to cancel transaction of {convertToIndianFormat(Math.ceil(amtTobePaid))} ?</Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: "15px !important"}}>Once canceled, all your reserved FRAX will be freed</Typography>
                            </Stack>
                            <Box>
                                <DividerComponent />
                            </Box>
                            <Box>
                                <Box >
                                    <Button onClick={handleSubmit} fullWidth variant="contained" >Cancel Transaction</Button>
                                </Box>
                            </Box>
                        </Stack>
                    </CardContent>
                </Card>
        </Dialog>
    )
}
