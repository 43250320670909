import React, { useEffect, useState } from "react";
import transactionServices from "../../services/transactionServices";
import { convertToIndianFormat, getTitle } from "../../utils/functions";
import moment from "moment";
import { AmountPaidCommited, DetailButton, TableRowImg, TableRowMultilineInfo, TxnRefId, TxnRefNumber } from "../miniComponents";
import { TableRow } from "@mui/material";
import CustomTable from "../customTable";
import { useNavigate } from "react-router-dom";

const AllTransactionByListing = ({ data }) => {
  const [transactionData, setTransactionData] = useState();
  const navigate = useNavigate();

  const getAllTransactrions = async () => {
    const res = await transactionServices.getTransactionByLisitngAndUser(data);    
    const tempData = [];
    if (res && res.success && res.data?.length > 0) {
      res.data.map((e) => {

        tempData.push({
          _id:e._id,
        rpf: (e?.rpf || e?.amount / e?.noOfFracs)?.toFixed(2),
        projectName: e.project.projectName,
        propertyName:getTitle(e.listing),
        propertyType:e.listing?.propertyType,
        amount:convertToIndianFormat(e.amount),
        amtTobePaid:convertToIndianFormat(e?.amtTobePaid || 0),
        area:e?.noOfFracs || 0,
        propertyPercent: e.propertyPercent,
        propertyStatus: e.listing?.status || "",
        pendingAmt: convertToIndianFormat(e.pendingAmt) || 0,
        remAmt:e?.pendingAmt > 0 ? e?.pendingAmt : 0,
        created_at: moment(e.created_at).format("DD/MM/YYYY"),
        updated_at: moment(e.updated_at).format("DD/MM/YYYY"),
        status: e.status,
        refNo: e.transactionId,
        listingId:e.listingId,
        refId:e.banktransferInfo.chequeno
        });
      });
    }
    setTransactionData(tempData)
  };
  
  useEffect(() => {
    getAllTransactrions();
  }, []);

  const tableHeads = [
   
    {
        key:"amtTobePaid",
        title:"Invested Amount"
    },
  {
    key:"refId",
    title:"Referance ID"
  },
    {
        key:"area",
        title:"No of\nFRAX"
    },
    {
        key:"rpf",
        title:"Rate/\nFRAX"
    },
    {
        key:"created_at",
        title:"Committed\nDate"
    },
    {
        key:"updated_at",
        title:"Payment Date"
    },
    {
        key:"refNo",
        title:"Transaction ID",
    },
    {
        key:"click",
        title:"Details",
        onClick:(data)=>{
          console.log(data);
          
          navigate(`/dashboard/property/${data.listingId}`,{state:{id:data.listingId}})
        }
    },
];
const tableCells = [<TableRow/>,<TxnRefId/>,<TableRow />,<TableRow />,<TableRow />,<TableRow />,<TxnRefNumber/>,<DetailButton/>];
  return (  <CustomTable tableHeads={tableHeads} tableCells={tableCells} data={transactionData} />)
};

export default AllTransactionByListing;
