import React, { useEffect, useState } from 'react'
import ModalComponent from './modalComponent'
import { Box, Button, Stack, Typography } from '@mui/material'
import Lottie from 'lottie-react';
import error from "../../animations/error.json"

export default function WrongMnemonicErrorMsg ({open,handleClose,openedFrom}) {

    const [animatingWar, setAnimatingWar] = useState(false);
    useEffect(() => {
        if (open) {
            setAnimatingWar(true);
        }
      }, [open]);
    
  return (
    <ModalComponent open={open} handleClose={handleClose}>
          <Box sx={{ textAlign: 'center', bgcolor: '#fdedec', borderRadius: 2, padding: "24px 12px" }}>
              <Stack sx={{ alignItems: 'center', justifyContent: 'center', bgcolor: '#fdedec', borderRadius: 2, padding: 1, minWidth: { md: '600px' }, mt: 2 }}>
                  {
                      animatingWar &&
                      <Box sx={{ width: 150, marginTop: "-35px" }}>
                          <Lottie animationData={error} loop={false} />
                      </Box>

                  }
                  {
                    openedFrom == 'mnemonicText' ?<Typography variant='landingSubTitle' sx={{ textAlign:'center',lineHeight:'35px'}}>The private key entered is incorrect <br></br> Please check your private key again</Typography>
                    : <Typography variant='landingSubTitle' sx={{ textAlign:'center',lineHeight:'35px'}}>The encrypted file uploaded is incorrect <br></br>Please use the correct encrypted file</Typography>
    
                  }  
              </Stack>
          </Box>      
    </ModalComponent>
  )
}
