import {
  Button,
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Box
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { FormDatePicker } from "../../../components/form/datePicker";
import FormField from "../../../components/form/formField";
import FormSelect from "../../../components/form/formSelect";
import { saveUser } from "../../../redux/reducers/userSlice";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import SecondaryBlockchainAccountModal from "../../../components/modals/secondaryBlockchainAccount";
import AreYouSureModal from "../../../components/modals/areYouSureModal";
import userServices from "../../../services/userServices";
import authServices from "../../../services/authServices";
import EditIcon from '@mui/icons-material/Edit';
import CustomIcon from "../../../components/customIcon";
import moment from "moment";

export default function MyProfile() {
  const user = useSelector((state) => state.user.data);
  const [isEditing, setIsEditing] = useState(false);
  const [isBankEditing, setIsBankEditing] = useState(false);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [availableBlockchainAccounts, setAvailableBlockchainAccounts] = useState([]);
  const [blockchainAccountModal, setBlockchainAccountModal] = useState(false);
  const [usernameError, setUsernameError] = useState(false);

  useEffect(() => {
    fetchDltWallet()
  }, [user])

  const fetchDltWallet = async () => {
    let dltWallet = await authServices.getDltWalletByUser(user._id)
    let temp = [user?.walletInfo?.publicAddress];
    if (user?.walletInfo?.secondaryAccounts) temp = [...temp, ...user?.walletInfo?.secondaryAccounts]
    const mappedEntries = temp.map(address => {
      const match = dltWallet.data.findIndex(obj => obj.publicAddress === address);
      return match === -1 ? { publicAddress: address, name: "", created_at: "" } : { ...dltWallet.data[match] };
    });
    setAvailableBlockchainAccounts(mappedEntries)
  }

  const [formData, setFormData] = useState({
    username: "",
    mobile: "",
    gender: "",
    citizenship: "",
    dob: null,
    userAddress: {
      flatNo: "",
      area: "",
      landmark: "",
      town: "",
      state: "",
      pincode: "",
    },
    aadharAddress: {
      flatNo: "",
      area: "",
      landmark: "",
      town: "",
      state: "",
      pincode: "",
    },
  });
  const [bankDetails, setBankDetails] = useState({
    accountNumber: "",
    ifscCode: "",
  });

  useEffect(() => {
    if (user) {
      setFormData({
        username: user.username || "",
        mobile: user.mobileNumber || "",
        gender: user.gender || "",
        citizenship: user.citizenShip || "",
        dob: user.dob ? new Date(user.dob * 1000) : null,
        userAddress: {
          flatNo: user?.userAddress?.flatNo,
          area: user?.userAddress?.area,
          landmark: user?.userAddress?.landmark,
          town: user?.userAddress?.town,
          state: user?.userAddress?.state,
          pincode: user?.userAddress?.pincode,
        },
        aadharAddress: {
          flatNo: user?.aadharAddress?.flatNo,
          area: user?.aadharAddress?.area,
          landmark: user?.aadharAddress?.landmark,
          town: user?.aadharAddress?.town,
          state: user?.aadharAddress?.state,
          pincode: user?.aadharAddress?.pincode,
        },
      });
      setBankDetails({
        accountNumber: user.bankInformation?.details?.account_number || "",
        ifscCode: user.bankInformation?.details?.ifsc || "",
      });
    }
  }, [user]);

  const genderOptions = [
    { title: "Male", value: "Male" },
    { title: "Female", value: "Female" },
    { title: "Others", value: "Others" },
  ];

  const citizenshipOptions = [{ title: "Indian", value: "INDIAN" }];

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleBankEditClick = () => {
    setIsBankEditing(true);
  };

  const handleSaveClick = async () => {
    setIsEditing(false);
    let userDetails = { ...formData, dob: new Date(formData.dob).valueOf() / 1000, _id: user._id }
    const res = await authServices.updatePersonalDetails(userDetails);
  };

  const handleBankSaveClick = async () => {
    setIsBankEditing(false);
    let updatedBankDetails = { ...bankDetails, _id: user._id }
    const res = await authServices.updateBankDetails(updatedBankDetails);
  };


  const handleCancelClick = () => {
    if (user) {
      setFormData({
        username: user.username || "",
        mobile: user.mobileNumber || "",
        gender: user.gender || "",
        citizenship: user.citizenShip || "",
        dob: user.dob ? new Date(user.dob * 1000) : null,
        userAddress: {
          flatNo: user?.userAddress?.flatNo,
          area: user?.userAddress?.area,
          landmark: user?.userAddress?.landmark,
          town: user?.userAddress?.town,
          state: user?.userAddress?.state,
          pincode: user?.userAddress?.pincode,
        },
        aadharAddress: {
          flatNo: user?.aadharAddress?.flatNo,
          area: user?.aadharAddress?.area,
          landmark: user?.aadharAddress?.landmark,
          town: user?.aadharAddress?.town,
          state: user?.aadharAddress?.state,
          pincode: user?.aadharAddress?.pincode,
        },
      });
    }
    setIsEditing(false);
  };

  const handleBankCancelClick = () => {
    if (user) {
      setBankDetails({
        accountNumber: user.bankDetails?.accountNumber || "",
        ifscCode: user.bankDetails?.ifscCode || "",
      });
    }
    setIsBankEditing(false);
  };

  const handleChange = (field, value) => {
    setFormData((prevState) => {
      const keys = field.split('.');

      if (keys.length === 1) {
        return {
          ...prevState,
          [field]: value,
        };
      }

      const [mainKey, subKey] = keys;
      return {
        ...prevState,
        [mainKey]: {
          ...prevState[mainKey],
          [subKey]: value,
        },
      };
    });
  };

  const handleBankChange = (field, value) => {
    setBankDetails((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container>
      <Card sx={{ mt: 5 }}>
        <SecondaryBlockchainAccountModal open={blockchainAccountModal} setOpen={setBlockchainAccountModal} user={user} dispatch={dispatch} />
        <CardContent sx={{ mx: { lg: 5, md: 4, sm: 2 } }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mt={3}
          >
            <Typography variant="mainCardTitle">
              My Profile
            </Typography>
            {isEditing ? (
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveClick}
                  disabled={usernameError}
                  sx={{ padding: '10px 12px !important' }}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleCancelClick}
                  sx={{ padding: '10px 12px !important' }}
                >
                  Cancel
                </Button>
              </Stack>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleEditClick}
                sx={{ padding: '10px 12px !important' }}
              >
                Edit Profile
              </Button>
            )}
          </Stack>
          <Divider
            sx={{
              width: '100%',
              mt: 3,
              mb: 5,
            }}
          />
          <Stack>
            <Box sx={{ padding: "20px", borderRadius: "10px", boxShadow: "0px 0px 30px 0px #51515129" }}>
              <Typography variant="headerMenu">Personal Info</Typography>
              <Typography variant="subtitle1">
                Update your personal details here
              </Typography>
              < Stack direction={isSmallScreen ? "column" : "row"} spacing={{ lg: 5, md: 5, sm: 2 }} mt={3}>
                <Stack>
                  <FormField
                    label={"Full Name"}
                    inputProps={{
                      disabled: !isEditing,
                      value: formData.username,
                      onChange: (e) => {
                        if (e.target.value?.trim() == "") setUsernameError(true);
                        else setUsernameError(false);
                        handleChange("username", e.target.value);
                      },
                    }}
                  />
                  {usernameError && <Typography sx={{ fontSize: "0.9rem !important", marginTop: "5px", color: "red" }}>Invalid Username!</Typography>}
                </Stack>
                <FormField
                  label={"Legal Name"}
                  inputProps={{
                    disabled: true,
                    value: user?.legalName
                  }}
                />
                <FormField
                  label={"Mobile no"}
                  inputProps={{
                    disabled: true,
                    value: formData.mobile
                  }}
                />

              </Stack>


              <Stack direction={isSmallScreen ? "column" : "row"} spacing={{ lg: 5, md: 5, sm: 2 }} mt={3}>
                {" "}
                <FormDatePicker
                  label="Date Of Birth"
                  props={{
                    disabled: !isEditing,
                    value: formData.dob,
                    onChange: (date) => handleChange("dob", date),
                  }}
                />
                <FormSelect
                  options={citizenshipOptions}
                  label="Citizenship"
                  props={{
                    disabled: !isEditing,
                    value: formData.citizenship,
                    onChange: (e) => handleChange("citizenship", e.target.value),
                  }}
                />
                <FormSelect
                  options={genderOptions}
                  label="Gender"
                  props={{
                    disabled: !isEditing,
                    value: formData.gender,
                    onChange: (e) => handleChange("gender", e.target.value),
                  }}
                />
              </Stack>

              {/* <Divider
          sx={{
            width:'100%',
            mt: 3,
            mb: 5,
          }}
        /> */}
            </Box>
            <Box sx={{ padding: "20px", borderRadius: "10px", boxShadow: "0px 0px 30px 0px #51515129", marginTop: "30px" }}>
              <Typography variant="headerMenu">Address Info</Typography>

              <Typography variant="h5" sx={{ marginTop: 3, fontWeight: "400" }}>
                Correspondence Address
              </Typography>
              <Stack direction={isSmallScreen ? "column" : "row"} spacing={{ lg: 5, md: 5, sm: 2 }} mt={1}>
                <FormField
                  label={"Flat No,House No"}
                  inputProps={{
                    disabled: !isEditing,
                    value: formData.userAddress.flatNo,
                    onChange: (e) => handleChange("userAddress.flatNo", e.target.value),
                  }}
                />
                <FormField
                  label={"Area,Street"}
                  inputProps={{
                    disabled: !isEditing,
                    value: formData.userAddress.area,
                    onChange: (e) => handleChange("userAddress.area", e.target.value),
                  }}
                />
                <FormField
                  label={"Landmark"}
                  inputProps={{
                    disabled: !isEditing,
                    value: formData.userAddress.landmark,
                    onChange: (e) => handleChange("userAddress.landmark", e.target.value),
                  }}
                />
              </Stack>
              <Stack direction={isSmallScreen ? "column" : "row"} spacing={{ lg: 5, md: 5, sm: 2 }} mt={3}>
                <FormField
                  label={"Town/City"}
                  inputProps={{
                    disabled: !isEditing,
                    value: formData.userAddress.town,
                    onChange: (e) => handleChange("userAddress.town", e.target.value),
                  }}
                />
                <FormField
                  label={"State"}
                  inputProps={{
                    disabled: !isEditing,
                    value: formData.userAddress.state,
                    onChange: (e) => handleChange("userAddress.state", e.target.value),
                  }}
                />
                <FormField
                  label={"Pincode"}
                  inputProps={{
                    disabled: !isEditing,
                    value: formData.userAddress.pincode,
                    onChange: (e) => handleChange("userAddress.pincode", e.target.value),
                  }}
                />
              </Stack>
              <Divider
                sx={{
                  width: '100%',
                  mt: 3,
                  mb: 5,
                }}
              />
              <Typography variant="landingSubText" sx={{ marginTop: 5 }}>
                Aadhar Address
              </Typography>
              <Stack direction={isSmallScreen ? "column" : "row"} spacing={{ lg: 5, md: 5, sm: 2 }} mt={1}>
                <FormField
                  label={"Flat No,House No"}
                  inputProps={{
                    disabled: true,
                    value: formData.aadharAddress.flatNo
                  }}
                />
                <FormField
                  label={"Area,Street"}
                  inputProps={{
                    disabled: true,
                    value: formData.aadharAddress.area
                  }}
                />
                <FormField
                  label={"Landmark"}
                  inputProps={{
                    disabled: true,
                    value: formData.aadharAddress.landmark
                  }}
                />
              </Stack>
              <Stack direction={isSmallScreen ? "column" : "row"} spacing={{ lg: 5, md: 5, sm: 2 }} mt={3}>
                <FormField
                  label={"Town/City"}
                  inputProps={{
                    disabled: true,
                    value: formData.aadharAddress.town
                  }}
                />
                <FormField
                  label={"State"}
                  inputProps={{
                    disabled: true,
                    value: formData.aadharAddress.state
                  }}
                />
                <FormField
                  label={"Pincode"}
                  inputProps={{
                    disabled: true,
                    value: formData.aadharAddress.pincode
                  }}
                />
              </Stack>

              {/* <Divider
          sx={{
            width: "100%",
            mt: 3,
            mb: 5,
          }}
        /> */}
            </Box>
            <Box sx={{ padding: "20px", borderRadius: "10px", boxShadow: "0px 0px 30px 0px #51515129", marginTop: "30px" }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="headerMenu">Bank Details</Typography>
                {isBankEditing ? (
                  <Stack direction="row" spacing={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleBankSaveClick}
                      sx={{ padding: '10px 12px !important' }}
                    >
                      Save
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleBankCancelClick}
                      sx={{ padding: '10px 12px !important' }}
                    >
                      Cancel
                    </Button>
                  </Stack>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleBankEditClick}
                    sx={{ padding: '10px 12px !important' }}
                  >
                    Edit
                  </Button>
                )}
              </Stack>
              <Stack direction={{ md: "row", xs: "column" }} spacing={{ lg: 5, md: 5, xs: 2 }} mt={3}>
                <FormField
                  label={"Account Number"}
                  inputProps={{
                    disabled: !isBankEditing,
                    value: bankDetails.accountNumber,
                    onChange: (e) =>
                      handleBankChange("accountNumber", e.target.value),
                  }}
                />
                <FormField
                  label={"IFSC Code"}
                  inputProps={{
                    disabled: !isBankEditing,
                    value: bankDetails.ifscCode,
                    onChange: (e) => handleBankChange("ifscCode", e.target.value),
                  }}
                />
              </Stack>

              {/* <Divider sx={{
                          width:"100%",
                          mt:3,
                          mb:5,
                      }} /> */}
            </Box>
            <Box sx={{ padding: "20px", borderRadius: "10px", boxShadow: "0px 0px 30px 0px #51515129", marginTop: "30px" }}>
              <BlockchainSection
                availableBlockchainAccounts={availableBlockchainAccounts}
                setBlockchainAccountModal={setBlockchainAccountModal}
                user={user}
                dispatch={dispatch}
              />
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </Container>
  );
}

function BlockchainSection({ availableBlockchainAccounts, user, dispatch, setBlockchainAccountModal }) {

  const { enqueueSnackbar } = useSnackbar();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [primaryOpen, setPrimaryOpen] = useState(false);

  const handlePrimary = async () => {
    const res = await userServices.updatePrimaryDLTAccount({ investorId: user?._id, accountIndex: currentIndex });
    if (res && res.success) {
      dispatch(saveUser(res.data));
    }
  }

  const handleRemove = async () => {
    const res = await userServices.removeDLTAccount({ investorId: user?._id, accountIndex: currentIndex });
    if (res && res.success) {
      dispatch(saveUser(res.data));
      enqueueSnackbar("DLT Account Deleted Successfully!", { variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" }, autoHideDuration: 3000 });
    } else enqueueSnackbar("This DLT Account is Holding Some Digital Assets in it. Please Contact RealX Admin for More Information!", { variant: "error", anchorOrigin: { horizontal: "center", vertical: "bottom" }, autoHideDuration: 9000 });
  }

  const handleDLTCreationStatus = async (investorId, status) => {
    const res = await userServices.updateDLTCreationStatus({ investorId, status });
    if (res && res.success) {
      dispatch(saveUser(res.data));
    }
  }

  const copyUrl = (account) => {
    navigator.clipboard.writeText(account);
    enqueueSnackbar("Copied to Clickboard", { variant: "success", anchorOrigin: { horizontal: "right", vertical: "top" }, autoHideDuration: 800 })

  };


  return (
    <Stack spacing={3}>

      <AreYouSureModal open={primaryOpen} setOpen={setPrimaryOpen} handleYes={handlePrimary} message={"Are You Sure About Updating your Primary DLT Account?"} publicAddress={availableBlockchainAccounts[currentIndex]?.publicAddress} publicAddressMessage={"Your New Primary DLT Account Address"} />
      <AreYouSureModal open={deleteOpen} setOpen={setDeleteOpen} handleYes={handleRemove} message={"Are You Sure About Permanently Deleting this DLT Account?"} note={"This action can not be undo!"} publicAddress={availableBlockchainAccounts[currentIndex]?.publicAddress} publicAddressMessage={"DLT Account Address To Be Removed"} />

      <Typography variant="headerMenu">Available Blockchain Accounts</Typography>

      <Stack flexDirection={"column"}  sx={{ overflowX: { xs: 'auto', sm: 'auto', md: 'hidden' },flexWrap:"wrap" }}>

        {(availableBlockchainAccounts[0] !== undefined && availableBlockchainAccounts.length >= 1) ? (
          availableBlockchainAccounts.map((account, index) => {

            return (
              <>

                <Stack flexDirection={"row"} columnGap={{ xs: 1, sm: 2 }} sx={{ width: { lg: "60%", md: "65%", sm: "85%", xs: "min-content" } }}  >
                  <Stack flexDirection={"row"} sx={{padding: "20px", borderRadius: "10px", boxShadow: "0px 0px 10px 0px #51515129", margin: "10px", width:"100%"}}  >
                    <Box sx={{ padding: "2px" }}>
                      <Box sx={{ display: "flex" }}> <Typography>#{index + 1}. ...{account.publicAddress?.slice(-20)}</Typography>
                      <Box onClick={(e) => { e.stopPropagation(); e.preventDefault(); copyUrl(account.publicAddress);}} sx={{ bgcolor: '#f4f6f7', padding: '7px', borderRadius: '50%', marginLeft: '10px',hight:"20px",width:"30px" }}>
                        <CustomIcon
                          name="copy"
                          sx={{ bgcolor: "#FFF", backgroundColor: "#333333", cursor: "pointer" }}
                        />
                      </Box>
                      <Box sx={{ marginLeft: "4px" }}>
                          {index == 0 ? (
                            <>
                              <Typography sx={{ color: "white", background: "#21ab37", padding: "2px 15px !important", fontSize: "12px !important", border: "1px solid #21ab37", borderRadius: "10px" }}>Primary Account</Typography>

                            </>
                          )
                            : <Stack flexDirection={"row"} alignItems={'center'} columnGap={{ xs: 1, sm: 2 }} sx={{ m: "0 !important" }}>
                              <Button
                                variant="contained"
                                sx={{ p: "4px 15px !important", fontSize: "12px !important", marginLeft: '5px' }}
                                onClick={() => { setCurrentIndex(index); setPrimaryOpen(true) }}
                              >Make Primary</Button>
                              <RemoveCircleOutlineIcon sx={{ color: "red", cursor: "pointer" }} onClick={() => { setCurrentIndex(index); setDeleteOpen(true) }} />

                            </Stack>}
                        </Box>
                      </Box>
                      
                      <Box sx={{ display: "flex", flexDirection:"row",justifyContent:"space-between",paddingTop:"20px" }}>
                        <Box>
                          <Typography sx={{fontWeight:"600"}}>{account?.name?.slice(0, 30)}</Typography>
                        </Box>
                       <Box>
                       <Typography >{account?.created_at ? ` Created On: ${moment(account?.created_at).format("DD-MM-YYYY")}` : ""}</Typography>
                       </Box>
                      </Box>
                      
                    </Box>
                  </Stack>

                </Stack>

                {/* <Divider sx={{
                  width: "100%",
                  mt: 3,
                  mb: 5,
                }} /> */}
              </>
            )
          })
        ) :
          (
            <>
              <Typography variant="subtitle1">You have not made any DLT accounts yet</Typography>
            </>
          )
        }

      </Stack>

      <Stack flexDirection={"row"} gap={1}>
        <Button
          onClick={() => user?.walletInfo?.isAllowedToCreateDLT == "APPROVED" ? setBlockchainAccountModal(true) : handleDLTCreationStatus(user?._id, "PENDING")}
          variant="contained"
          sx={{ width: "max-content", py: "10px !important" }}
          disabled={availableBlockchainAccounts?.length > 2 || user?.walletInfo?.isAllowedToCreateDLT == "PENDING"}
        >
          {user?.walletInfo?.isAllowedToCreateDLT == "APPROVED" ? "Create a New DLT Account" :
            user?.walletInfo?.isAllowedToCreateDLT == "PENDING" ? "Request Submitted to RealX Admin" : "Request to Create a New DLT Account"}
        </Button>

        {user?.walletInfo?.isAllowedToCreateDLT == "PENDING" && <Button
          variant="contained"
          sx={{ py: "7px !important" }}
          onClick={() => handleDLTCreationStatus(user?._id, "CREATED")}
        >
          Cancel Request
        </Button>}

      </Stack>

    </Stack>
  )
}